import { GUIDFunctions } from '@shared/utils';
import { ScreenType, ScreenTypeMaxWidth, ScreenTypeMinWidth } from './screentype.enum';

export interface PartPositioning {
    id: string;
    x: number;
    y: number;
    positionXUnit: string;
    positionYUnit: string;

    sizeX: number;
    sizeY: number;
    sizeXUnit: string;
    sizeYUnit: string;

    minSizeX: number;
    minSizeY: number;
    minSizeXUnit: string;
    minSizeYUnit: string;

    maxSizeX: number;
    maxSizeY: number;
    maxSizeXUnit: string;
    maxSizeYUnit: string;

    horizontalAlignment: string;
    verticalAlignment: string;
    inheritContentSize: boolean;
    fixedPosition: boolean;
    hidden: boolean;
    zIndex: number;
    screenType: string;
}

export function getClosestPartPositioning(minWidth: number, maxWidth: number, partPositioningList: PartPositioning[]): PartPositioning {
    const sortedPositions = partPositioningList
        .filter(partPosition => ScreenType[partPosition.screenType as keyof typeof ScreenType] !== ScreenType.ALL_SCREENS)
        .sort((a, b) => ScreenType[a.screenType as keyof typeof ScreenType] - ScreenType[b.screenType as keyof typeof ScreenType]);
    const allScreens = partPositioningList.find(
        partPosition => ScreenType[partPosition.screenType as keyof typeof ScreenType] === ScreenType.ALL_SCREENS
    );
    if (sortedPositions.length > 0) {
        let possiblePositioning = !!allScreens ? allScreens : sortedPositions[0];
        sortedPositions.forEach(partPositioning => {
            if (!!allScreens) {
                const minWidthForPartPositioning = ScreenTypeMinWidth.get(
                    ScreenType[partPositioning.screenType as keyof typeof ScreenType]
                );
                const maxWidthForPartPositioning = ScreenTypeMaxWidth.get(
                    ScreenType[partPositioning.screenType as keyof typeof ScreenType]
                );
                if (
                    (!minWidthForPartPositioning || minWidthForPartPositioning === minWidth) &&
                    (!maxWidthForPartPositioning || maxWidthForPartPositioning === maxWidth)
                ) {
                    possiblePositioning = partPositioning;
                    return;
                }
            } else {
                const minWidthForPartPositioning = ScreenTypeMinWidth.get(
                    ScreenType[partPositioning.screenType as keyof typeof ScreenType]
                );
                if (!minWidthForPartPositioning || minWidthForPartPositioning <= minWidth) {
                    possiblePositioning = partPositioning;
                } else if (minWidthForPartPositioning && minWidthForPartPositioning > minWidth) {
                    return;
                }
            }
        });
        return possiblePositioning;
    } else {
        return partPositioningList[0];
    }
}

export function selectCurrentPartPositioningCreateNewIfNotExisting(
    currentScreenType: number | undefined,
    partPositionings: PartPositioning[]
): PartPositioning {
    if (currentScreenType || currentScreenType === 0) {
        const foundPartPositioning = selectCurrentPartPositioning(currentScreenType, partPositionings);
        if (!partPositionings.find(partPositioning => partPositioning.screenType === foundPartPositioning.screenType)) {
            partPositionings.push(foundPartPositioning);
        }
        return foundPartPositioning;
    } else {
        return null;
    }
}

export function selectBasedOnPartPositioning(currentScreenType: number, partPositionings: PartPositioning[]) {
    const allScreens = partPositionings.find(partPositioning => partPositioning.screenType === ScreenType[ScreenType.ALL_SCREENS]);
    if (!!allScreens && (partPositionings.length === 1 || currentScreenType === 5)) {
        return ScreenType[ScreenType.ALL_SCREENS];
    } else {
        const closestPartPositioning = getClosestPartPositioning(
            ScreenTypeMinWidth.get(currentScreenType),
            ScreenTypeMaxWidth.get(currentScreenType),
            partPositionings
        );
        return closestPartPositioning.screenType;
    }
}

export function selectCurrentPartPositioning(currentScreenType: number, partPositionings: PartPositioning[]): PartPositioning {
    if (partPositionings) {
        const screenType: string = ScreenType[currentScreenType];
        const foundPartPositioning = partPositionings.find(partPositioning => partPositioning.screenType === screenType);
        // If this is not found, create one here?
        if (!foundPartPositioning) {
            const closedPartPositioning: PartPositioning = getClosestPartPositioning(
                ScreenTypeMinWidth.get(currentScreenType),
                ScreenTypeMaxWidth.get(currentScreenType),
                partPositionings
            );
            return {
                id: new GUIDFunctions().newGuid(),
                x: closedPartPositioning.x,
                y: closedPartPositioning.y,
                positionXUnit: closedPartPositioning.positionXUnit,
                positionYUnit: closedPartPositioning.positionYUnit,
                sizeX: closedPartPositioning.sizeX,
                sizeY: closedPartPositioning.sizeY,
                sizeXUnit: closedPartPositioning.sizeXUnit,
                sizeYUnit: closedPartPositioning.sizeYUnit,

                minSizeX: closedPartPositioning.minSizeX,
                minSizeY: closedPartPositioning.minSizeY,
                minSizeXUnit: closedPartPositioning.minSizeXUnit,
                minSizeYUnit: closedPartPositioning.minSizeYUnit,

                maxSizeX: closedPartPositioning.maxSizeX,
                maxSizeY: closedPartPositioning.maxSizeY,
                maxSizeXUnit: closedPartPositioning.maxSizeXUnit,
                maxSizeYUnit: closedPartPositioning.maxSizeYUnit,

                horizontalAlignment: closedPartPositioning.horizontalAlignment,
                verticalAlignment: closedPartPositioning.verticalAlignment,
                inheritContentSize: closedPartPositioning.inheritContentSize,
                fixedPosition: closedPartPositioning.fixedPosition,
                hidden: closedPartPositioning.hidden,
                zIndex: closedPartPositioning.zIndex,
                screenType: ScreenType[currentScreenType],
            };
        }
        return foundPartPositioning;
    } else {
        return null;
    }
}
