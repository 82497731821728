export interface PaddingStyleDto {
    padding: string;
}

export function generatePaddingStyle(
    paddingStyle: PaddingStyleDto | null | undefined,
    paddingLeftStyle: PaddingStyleDto | null | undefined,
    paddingRightStyle: PaddingStyleDto | null | undefined,
    paddingTopStyle: PaddingStyleDto | null | undefined,
    paddingBottomStyle: PaddingStyleDto | null | undefined
) {
    let style = '';
    if (paddingStyle) {
        style += generateTypePaddingStyle('padding', paddingStyle);
    }
    if (paddingLeftStyle) {
        style += generateTypePaddingStyle('padding-left', paddingLeftStyle);
    }
    if (paddingTopStyle) {
        style += generateTypePaddingStyle('padding-top', paddingTopStyle);
    }
    if (paddingRightStyle) {
        style += generateTypePaddingStyle('padding-right', paddingRightStyle);
    }
    if (paddingBottomStyle) {
        style += generateTypePaddingStyle('padding-bottom', paddingBottomStyle);
    }
    return style;
}

export function generateTypePaddingStyle(type: string, paddingStyleDto: PaddingStyleDto) {
    let style = '';
    if (paddingStyleDto.padding) {
        style += type + ':' + paddingStyleDto.padding + ';';
    }
    return style;
}
