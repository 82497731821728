<codex-overflow-wrapper
    [identifier]="part.id"
    [wrapperStyle]="_containerStyle"
    [allowedTypes]="['normal', 'hover']"
    (wrapperStyleUpdated)="this.containerStyleUpdated.emit({ containerStyle: this._containerStyle })"
    [hoverWrapperStyle]="_hoverContainerStyle"
    (hoverWrapperStyleUpdated)="this.hoverContainerStyleUpdated.emit({ containerStyle: this._hoverContainerStyle })">
</codex-overflow-wrapper>
<codex-border-picker-wrapper
    [identifier]="part.id"
    [wrapperStyle]="_containerStyle"
    [allowedTypes]="['normal', 'hover']"
    (wrapperStyleUpdated)="this.containerStyleUpdated.emit({ containerStyle: this._containerStyle })"
    [hoverWrapperStyle]="_hoverContainerStyle"
    (hoverWrapperStyleUpdated)="this.hoverContainerStyleUpdated.emit({ containerStyle: this._hoverContainerStyle })">
</codex-border-picker-wrapper>
<codex-padding-picker-wrapper
    [identifier]="part.id"
    [wrapperStyle]="_containerStyle"
    [allowedTypes]="['normal', 'hover']"
    (wrapperStyleUpdated)="this.containerStyleUpdated.emit({ containerStyle: this._containerStyle })"
    [hoverWrapperStyle]="_hoverContainerStyle"
    (hoverWrapperStyleUpdated)="this.hoverContainerStyleUpdated.emit({ containerStyle: this._hoverContainerStyle })">
</codex-padding-picker-wrapper>
<codex-background-picker-wrapper
    [identifier]="part.id"
    [wrapperStyle]="_containerStyle"
    [allowedTypes]="['normal', 'hover']"
    (wrapperStyleUpdated)="this.containerStyleUpdated.emit({ containerStyle: this._containerStyle })"
    [hoverWrapperStyle]="_hoverContainerStyle"
    (hoverWrapperStyleUpdated)="this.hoverContainerStyleUpdated.emit({ containerStyle: this._hoverContainerStyle })">
</codex-background-picker-wrapper>
<codex-shadow-picker-wrapper
    [identifier]="part.id"
    [wrapperStyle]="_containerStyle"
    [allowedTypes]="['normal', 'hover']"
    (wrapperStyleUpdated)="this.containerStyleUpdated.emit({ containerStyle: this._containerStyle })"
    [hoverWrapperStyle]="_hoverContainerStyle"
    (hoverWrapperStyleUpdated)="this.hoverContainerStyleUpdated.emit({ containerStyle: this._hoverContainerStyle })">
</codex-shadow-picker-wrapper>
<codex-cursor-picker-wrapper
    [identifier]="part.id"
    [wrapperStyle]="_containerStyle"
    [allowedTypes]="['normal', 'hover']"
    (wrapperStyleUpdated)="this.containerStyleUpdated.emit({ containerStyle: this._containerStyle })"
    [hoverWrapperStyle]="_hoverContainerStyle"
    (hoverWrapperStyleUpdated)="
        this.hoverContainerStyleUpdated.emit({ containerStyle: this._hoverContainerStyle })
    "></codex-cursor-picker-wrapper>
<codex-font-picker-wrapper
    [identifier]="part.id"
    [wrapperStyle]="_containerStyle"
    [allowedTypes]="['normal', 'hover']"
    (wrapperStyleUpdated)="this.containerStyleUpdated.emit({ containerStyle: this._containerStyle })"
    [hoverWrapperStyle]="_hoverContainerStyle"
    (hoverWrapperStyleUpdated)="
        this.hoverContainerStyleUpdated.emit({ containerStyle: this._hoverContainerStyle })
    "></codex-font-picker-wrapper>
