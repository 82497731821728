import { PartStyle } from '../../../part-style.model';
import { GenericMarginStyleDto } from '../../../../../interfaces/style/generic-margin.style.dto';

export class BarChartPartStyle extends PartStyle {
    series: BarChartSeriesStyle[] = [];
    legend = new BarChartLegendStyle();
    axisLabelInside: boolean;
    axisLabelColor: string;
    axisLabelRotation: number;

    gridMarginStyle: GenericMarginStyleDto;

    constructor(base?: Partial<BarChartPartStyle>) {
        super();

        if (!!base) {
            this.series = base.series ?? this.series;
            this.legend = base.legend ?? this.legend;
            this.axisLabelInside = base.axisLabelInside ?? this.axisLabelInside;
            this.axisLabelColor = base.axisLabelColor ?? this.axisLabelColor;
            this.axisLabelRotation = base.axisLabelRotation ?? this.axisLabelRotation;
            this.gridMarginStyle = base.gridMarginStyle ?? this.gridMarginStyle;
        }
    }
}

export class BarChartSeriesStyle {
    id: string;
    code: string;
    name: string;
    itemStyle = new BarChartSeriesItemStyleStyle();

    constructor(base?: Partial<BarChartSeriesStyle>) {
        if (!!base) {
            this.id = base.id ?? this.id;
            this.code = base.code ?? this.code;
            this.name = base.name ?? this.name;
            this.itemStyle = base.itemStyle ? new BarChartSeriesItemStyleStyle(base.itemStyle) : this.itemStyle;
        }
    }
}

export class BarChartSeriesItemStyleStyle {
    color?: string;

    constructor(base?: Partial<BarChartSeriesItemStyleStyle>) {
        if (!!base) {
            this.color = base.color ?? this.color;
        }
    }
}

export class BarChartLegendStyle {
    show = true;
    orient?: 'vertical' | 'horizontal' = 'horizontal';
    left?: 'auto' | 'left' | 'center' | 'right';
    top?: 'auto' | 'top' | 'middle' | 'bottom';
}
