import { PartStyle } from '../../../part-style.model';
import { GenericMarginStyleDto } from '../../../../../interfaces/style/generic-margin.style.dto';

const colors = ['#b30000', '#7c1158', '#4421af', '#1a53ff', '#0d88e6', '#00b7c7', '#5ad45a', '#8be04e', '#ebdc78'];

export class LineChartPartStyle extends PartStyle {
    series: LineChartSeriesStyle[] = [];
    legend = new LineChartLegendStyle();
    gridMarginStyle: GenericMarginStyleDto;

    constructor(base?: Partial<LineChartPartStyle>) {
        super();

        if (!!base) {
            this.series = base.series ?? this.series;
            this.legend = base.legend ?? this.legend;
            this.gridMarginStyle = base.gridMarginStyle ?? this.gridMarginStyle;
        }
    }
}

export class LineChartSeriesStyle {
    id?: string;
    code: string;
    name?: string;
    itemStyle = new LineChartSeriesItemStyleStyle();

    constructor(base?: Partial<LineChartSeriesStyle>) {
        if (!!base) {
            this.id = base.id ?? this.id;
            this.name = base.name ?? this.name;
            this.itemStyle = base.itemStyle ? new LineChartSeriesItemStyleStyle(base.itemStyle) : this.itemStyle;
        }
    }
}

export class LineChartSeriesItemStyleStyle {
    color?: string;

    constructor(base?: Partial<LineChartSeriesItemStyleStyle>) {
        if (!!base) {
            this.color = base.color ?? this.color;
        }
    }
}

export class LineChartLegendStyle {
    show = true;
    orient?: 'vertical' | 'horizontal' = 'horizontal';
    left?: 'auto' | 'left' | 'center' | 'right';
    top?: 'auto' | 'top' | 'middle' | 'bottom';
}

export function getDynamicColor(index: number) {
    if (colors.length > index) {
        return colors[index];
    } else {
        return colors[colors.length - 1];
    }
}
