<mat-accordion *ngIf="_genericPartStyle" class="inner-accordion">
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{ 'v2.template.style.border' | translate }}
                <button
                    class="resetButton"
                    type="button"
                    mat-icon-button
                    (click)="resetBorderStyle(); $event.stopPropagation()"
                    [matTooltip]="'v2.template.style.reset' | translate">
                    <mat-icon>restart_alt</mat-icon>
                </button>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <codex-border-picker
            [borderStyle]="_genericPartStyle.borderStyle"
            [borderTopStyle]="_genericPartStyle.borderTopStyle"
            [borderLeftStyle]="_genericPartStyle.borderLeftStyle"
            [borderBottomStyle]="_genericPartStyle.borderBottomStyle"
            [borderRightStyle]="_genericPartStyle.borderRightStyle"
            (changeBorder)="onChangeBorder($event)"></codex-border-picker>
    </mat-expansion-panel>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{ 'v2.template.style.padding' | translate }}
                <button
                    class="resetButton"
                    type="button"
                    mat-icon-button
                    (click)="resetPaddingStyle(); $event.stopPropagation()"
                    [matTooltip]="'v2.template.style.reset' | translate">
                    <mat-icon>restart_alt</mat-icon>
                </button>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <codex-padding-picker
            [paddingStyle]="_genericPartStyle.paddingStyle"
            [paddingTopStyle]="_genericPartStyle.paddingTopStyle"
            [paddingLeftStyle]="_genericPartStyle.paddingLeftStyle"
            [paddingBottomStyle]="_genericPartStyle.paddingBottomStyle"
            [paddingRightStyle]="_genericPartStyle.paddingRightStyle"
            (changePadding)="onChangePadding($event)"></codex-padding-picker>
    </mat-expansion-panel>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{ 'v2.template.style.background' | translate }}
                <button
                    class="resetButton"
                    type="button"
                    mat-icon-button
                    (click)="resetBackgroundStyle(); $event.stopPropagation()"
                    [matTooltip]="'v2.template.style.reset' | translate">
                    <mat-icon>restart_alt</mat-icon>
                </button>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <codex-background-picker
            [backgroundStyle]="_genericPartStyle.backgroundStyle"
            (changeBackground)="onChangeBackground($event)"></codex-background-picker>
    </mat-expansion-panel>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{ 'v2.part.style.shadow' | translate }}
                <button
                    class="resetButton"
                    type="button"
                    mat-icon-button
                    (click)="resetShadowStyle(); $event.stopPropagation()"
                    [matTooltip]="'v2.template.style.reset' | translate">
                    <mat-icon>restart_alt</mat-icon>
                </button>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <codex-shadow-picker [shadowStyle]="_genericPartStyle.shadowStyle" (changeShadow)="onChangeShadow($event)"></codex-shadow-picker>
    </mat-expansion-panel>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{ 'v2.part.style.cursor' | translate }}
                <button
                    class="resetButton"
                    type="button"
                    mat-icon-button
                    (click)="resetCursorStyle(); $event.stopPropagation()"
                    [matTooltip]="'v2.template.style.reset' | translate">
                    <mat-icon>restart_alt</mat-icon>
                </button>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <codex-cursor-picker [cursor]="_genericPartStyle?.cursorStyle?.type" (changeCursor)="onChangeCursor($event)"></codex-cursor-picker>
    </mat-expansion-panel>
</mat-accordion>
