import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { backofficeEnvironment } from '@shared/environment';
import { ActionTestDto } from '@backoffice/data-access/editor';
import { ActionTestCreated } from '../dto/action-test-created.dto';

@Injectable()
export class ActionTestService {
    constructor(private readonly httpClient: HttpClient) {}

    public createActionTest(
        applicationId: string,
        companyId: string,
        actionId: string,
        actionTest: ActionTestDto
    ): Observable<ActionTestCreated> {
        return this.httpClient.post<ActionTestCreated>(
            `${backofficeEnvironment.rest.v2.actionTests}company/${companyId}/application/${applicationId}/action/${actionId}`,
            actionTest
        );
    }

    public getActionTests(applicationId: string, companyId: string, actionId: string): Observable<ActionTestDto[]> {
        return this.httpClient.get<ActionTestDto[]>(
            `${backofficeEnvironment.rest.v2.actionTests}company/${companyId}/application/${applicationId}/action/${actionId}`
        );
    }

    public updateActionTest(
        applicationId: string,
        companyId: string,
        actionId: string,
        actionTestId: string,
        actionTest: ActionTestDto
    ): Observable<void> {
        return this.httpClient.put<void>(
            `${backofficeEnvironment.rest.v2.actionTests}company/${companyId}/application/${applicationId}/action/${actionId}/${actionTestId}`,
            actionTest
        );
    }
}
