import { Component, Input } from '@angular/core';
import { CompanyDto } from '@shared/interfaces/company.dto';

@Component({
    selector: 'codex-company-edit-api-security',
    templateUrl: './company-edit-api-security.component.html',
})
export class CompanyEditApiSecurityComponent {
    @Input()
    company: CompanyDto;
}
