import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { MatDialog as MatDialog, MatDialogRef as MatDialogRef } from '@angular/material/dialog';
import { backofficeEnvironment, BackofficeEnvironmentDto } from '@shared/environment';
import { take } from 'rxjs/operators';
import { MediaEditorFacade } from '@backoffice/data-access/editor';
import { CompanyDto } from '@shared/interfaces/company.dto';
import { Store } from '@ngrx/store';
import { ApplicationDto } from '../../../../../../data-access/editor/src/lib/interfaces/application.dto';
import { selectCurrentContext } from '../../../../../../../../apps/no-code-x-backoffice/src/app/store/data/authenticated.selector';
import { ApplicationState } from '../../../../../../../../apps/no-code-x-backoffice/src/app/store/application.state';
import { SearchMediaDialogComponent } from '../../../../../../ui/editor/src/lib/dialogs/search-media-dialog/search-media-dialog.component';
import { MediaCreateFormComponent } from '../../../../../../ui/editor/src/lib/components/media-create-form/media-create-form.component';

@Component({
    selector: 'codex-media-picker',
    templateUrl: './media-picker.component.html',
    styleUrls: ['./media-picker.component.scss'],
})
export class MediaPickerComponent implements OnDestroy {
    @Input() type: string;
    @Input() mediaId: string;

    @Output() mediaChanged: EventEmitter<{ mediaId: string }> = new EventEmitter();

    currentContext$: Observable<{
        userLanguage: string;
        selectedCompany: CompanyDto;
        selectedApplication: ApplicationDto;
    }> = this.store.select(selectCurrentContext);

    subscriptions: Subscription = new Subscription();

    private mediaPickerDialog: MatDialogRef<SearchMediaDialogComponent>;

    public environment: BackofficeEnvironmentDto = backofficeEnvironment;

    constructor(
        public dialog: MatDialog,
        private readonly mediaFacade: MediaEditorFacade,
        private store: Store<ApplicationState>
    ) {}

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    onSearchMedia() {
        this.mediaPickerDialog = this.dialog.open(SearchMediaDialogComponent, { ...backofficeEnvironment.dialogConfig.big });
        this.mediaPickerDialog
            .afterClosed()
            .pipe(take(1))
            .subscribe((value: { action: string; content: { typeId: string } }) => {
                const { action, content } = value;
                if (action === 'open') {
                    const { typeId } = content;
                    this.mediaChanged.emit({ mediaId: typeId });
                } else if (action === 'create') {
                    this.mediaFacade.create(MediaCreateFormComponent).subscribe();
                }
            });
    }
}
