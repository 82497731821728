<div
    *ngIf="{ currentContext: currentContext$ | async } as observables"
    (click)="onSearchMedia()"
    class="image rounded-lg border border-light-border dark:border-dark-border bg-dark-input-background_color">
    <img *ngIf="!mediaId" src="/images/theme/image_placeholder.svg" />
    <img
        *ngIf="!!mediaId"
        [src]="
            environment.rest.v2.media +
            'file/company/' +
            observables.currentContext.selectedCompany.id +
            '/application/' +
            observables.currentContext.selectedApplication.id +
            '/' +
            mediaId
        "
        width="200" />
    <span>{{ 'v2.part.image.media.select' | translate }}</span>
</div>
