import { ContainerStyleDto } from '../../../../../interfaces/style/container.style.dto';

export interface StepperStyle extends ContainerStyleDto {
    stepColor?: string | null;

    stepIconColor?: string | null;

    stepBackgroundColor?: string | null;

    currentStepAccentColor?: string | null;

    currentStepBackgroundColor?: string | null;

    currentStepIconColor?: string | null;
}
