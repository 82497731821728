import { backofficeEnvironment } from '@shared/environment';
import { ScreenTypeEditorHeight, ScreenTypeEditorWidth } from '../../models/template/screentype.enum';

export interface GridStyleDto {
    gridColumns: number;
    gridColumnSize?: number;
    gridColumnGapSize: number;
    gridColumnColor: string;

    gridRows: number;
    gridRowSize?: number;
    gridRowGapSize: number;
    gridRowColor: string;

    snapStrength: number;
    showGrid: boolean;
}

export function getSizeXForGridAmount(
    amountOfColumns: number,
    gridStyle: GridStyleDto,
    parentSizeX: number,
    parentSizeXUnit: string,
    currentViewScreenType: number
) {
    const columnSize = getGridColumnSize(gridStyle, parentSizeX, parentSizeXUnit, currentViewScreenType);
    return columnSize * amountOfColumns + gridStyle.gridColumnGapSize * (amountOfColumns - 1);
}

export function getSizeYForGridAmount(
    amountOfRows: number,
    gridStyle: GridStyleDto,
    parentSizeY: number,
    parentSizeYUnit: string,
    currentViewScreenType: number
) {
    const rowSize = getGridRowSize(gridStyle, parentSizeY, parentSizeYUnit, currentViewScreenType);
    return rowSize * amountOfRows + gridStyle.gridRowGapSize * (amountOfRows - 1);
}

export function getGridColumnSize(
    gridStyle: GridStyleDto,
    parentSizeX: number,
    parentSizeXUnit: string,
    currentViewScreenType: number
): number {
    let amountOfGaps = gridStyle.gridColumns + 1;
    if (backofficeEnvironment.addPaddingToGrid) {
        amountOfGaps = gridStyle.gridColumns - 1;
    }
    const gapSize = gridStyle.gridColumnGapSize;
    const amountOfColumns = gridStyle.gridColumns;
    if (parentSizeXUnit === 'pixels') {
        return (parentSizeX - gapSize * amountOfGaps) / amountOfColumns;
    } else if (parentSizeXUnit === 'percentage') {
        return (ScreenTypeEditorWidth.get(currentViewScreenType) - gapSize * amountOfGaps) / amountOfColumns;
    } else {
        return 0;
    }
}

export function getGridRowSize(gridStyle: GridStyleDto, parentSizeY: number, parentSizeYUnit: string, currentViewScreenType: number) {
    let amountOfGaps = gridStyle.gridRows + 1;
    if (backofficeEnvironment.addPaddingToGrid) {
        amountOfGaps = gridStyle.gridRows - 1;
    }
    const gapSize = gridStyle.gridRowGapSize;
    const amountOfRows = gridStyle.gridRows;
    if (parentSizeYUnit === 'pixels') {
        return (parentSizeY - gapSize * amountOfGaps) / amountOfRows;
    } else if (parentSizeYUnit === 'percentage') {
        return ScreenTypeEditorHeight.get(currentViewScreenType) - (gapSize * amountOfGaps) / amountOfRows;
    } else {
        return 0;
    }
}

export function drawGrid(gridStyle: GridStyleDto, width: number, height: number, scale: number): string {
    if (!!gridStyle) {
        gridStyle.gridColumnSize =
            (width * scale - gridStyle.gridColumnGapSize * scale * (gridStyle.gridColumns + 1)) / gridStyle.gridColumns;
        gridStyle.gridRowSize = (height * scale - gridStyle.gridRowGapSize * scale * (gridStyle.gridRows + 1)) / gridStyle.gridRows;
        if (gridStyle.showGrid) {
            const unScaledGridColumnSize =
                (width - gridStyle.gridColumnGapSize * (gridStyle.gridColumns + 1)) / gridStyle.gridColumns + gridStyle.gridColumnGapSize;
            const unScaledGridRowSize =
                (height - gridStyle.gridRowGapSize * (gridStyle.gridRows + 1)) / gridStyle.gridRows + gridStyle.gridRowGapSize;
            return `background-size: ${unScaledGridColumnSize}px ${unScaledGridRowSize}px; background-image: linear-gradient(rgba(0, 0, 0, 0.05) ${gridStyle.gridRowGapSize}px, ${gridStyle.gridRowColor}50 .1rem), linear-gradient(90deg, rgba(0, 0, 0, 0.05) ${gridStyle.gridColumnGapSize}px, ${gridStyle.gridColumnColor}50 .1rem); background-color: white;`;
        } else {
            return '';
        }
    } else {
        return '';
    }
}

export function drawGridWithPadding(gridStyle: GridStyleDto, width: number, height: number, scale: number): string {
    if (!!gridStyle) {
        gridStyle.gridColumnSize =
            (width * scale - gridStyle.gridColumnGapSize * scale * (gridStyle.gridColumns + 1)) / gridStyle.gridColumns;
        gridStyle.gridRowSize = (height * scale - gridStyle.gridRowGapSize * scale * (gridStyle.gridRows + 1)) / gridStyle.gridRows;
        if (gridStyle.showGrid) {
            const unScaledGridColumnSize =
                (width - gridStyle.gridColumnGapSize * (gridStyle.gridColumns - 1)) / gridStyle.gridColumns + gridStyle.gridColumnGapSize;
            const unScaledGridRowSize =
                (height - gridStyle.gridRowGapSize * (gridStyle.gridRows - 1)) / gridStyle.gridRows + gridStyle.gridRowGapSize;
            const unScaledGridColumnSizeWithFirstAndLastGap =
                (width - gridStyle.gridColumnGapSize * (gridStyle.gridColumns - 1)) / gridStyle.gridColumns;
            const unScaledGridRowSizeWithFirstAndLastGap =
                (height - gridStyle.gridRowGapSize * (gridStyle.gridRows - 1)) / gridStyle.gridRows;

            return `background-size: ${unScaledGridColumnSize}px ${unScaledGridRowSize}px; background-image: linear-gradient(rgba(0, 0, 0, 0.05) ${gridStyle.gridRowGapSize}px, ${gridStyle.gridColumnColor}50 .1rem), linear-gradient(90deg, rgba(0, 0, 0, 0.05) ${gridStyle.gridColumnGapSize}px, ${gridStyle.gridRowColor}50 .1rem); background-color: white; background-position: ${unScaledGridColumnSizeWithFirstAndLastGap}px ${unScaledGridRowSizeWithFirstAndLastGap}px;`;
        } else {
            return '';
        }
    } else {
        return '';
    }
}
