import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { AppFacade } from '@core/facades/app.facade';
import { ApplicationService } from '@core/services/application.service';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { ApplicationDto } from '../../../../../../data-access/editor/src/lib/interfaces/application.dto';

@Component({
    selector: 'app-application-picker',
    templateUrl: './application-picker.component.html',
})
export class ApplicationPickerComponent implements OnInit {
    @Input()
    label: string;

    @Input()
    selectedApplicationId: string;

    applications$: Observable<ApplicationDto[]>;

    @Output()
    applicationChanged: EventEmitter<{ application: ApplicationDto }> = new EventEmitter<{ application: ApplicationDto }>();

    constructor(
        private readonly appFacade: AppFacade,
        private readonly applicationService: ApplicationService
    ) {}

    ngOnInit(): void {
        this.applications$ = this.appFacade.context.pipe(
            filter(context => !!context && !!context.selectedCompany),
            take(1),
            switchMap(({ selectedCompany }) => this.applicationService.getApplications(selectedCompany.id).pipe(map(page => page.content)))
        );

        if (this.selectedApplicationId != null) {
            this.appFacade.context
                .pipe(
                    filter(context => !!context && !!context.selectedCompany),
                    take(1),
                    switchMap(({ selectedCompany }) => {
                        return this.applicationService.getApplication(this.selectedApplicationId, selectedCompany.id).pipe(take(1));
                    })
                )
                .subscribe(application => {
                    this.applicationChanged.emit({ application: application });
                });
        }
    }

    onApplicationSelect($event) {
        this.appFacade.context
            .pipe(
                filter(context => !!context && !!context.selectedCompany),
                take(1),
                switchMap(({ selectedCompany }) => {
                    return this.applicationService.getApplication(this.selectedApplicationId, selectedCompany.id).pipe(take(1));
                })
            )
            .subscribe(application => {
                this.applicationChanged.emit({ application: application });
            });
    }
}
