import { FontFamily } from './font/font-family.model';
import { FontSize } from './font/font-size.model';
import { FontWeight } from './font/font-weight.model';
import { FontStyle } from './font/font-style.model';
import { LetterSpacing } from './font/letter-spacing.model';
import { Variable } from './util/variable.model';
import { ColorV2 } from './color/colorv2.model';

export class Typography {
    color: ColorV2;
    fontFamily: FontFamily;
    fontSize: FontSize;
    fontWeight: FontWeight;
    fontStyle: FontStyle;
    letterSpacing: LetterSpacing;
    variableNames: {
        fontColor: Variable;
        fontFamily: Variable;
        fontSize: Variable;
        fontWeight: Variable;
        letterSpacing: Variable;
        fontStyle: Variable;
    };

    constructor(base?: Partial<Typography>) {
        Object.assign(this, base);
        this.variableNames = {
            fontColor: new Variable(base?.variableNames?.fontColor),
            fontFamily: new Variable(base?.variableNames?.fontFamily),
            fontSize: new Variable(base?.variableNames?.fontSize),
            fontWeight: new Variable(base?.variableNames?.fontWeight),
            letterSpacing: new Variable(base?.variableNames?.letterSpacing),
            fontStyle: new Variable(base?.variableNames?.fontStyle),
        };
    }
}
