export interface BackgroundStyleDto {
    backgroundColor?: string;
    backgroundImage?: string;
    backgroundImageUrl?: string;
    backgroundImageLinkType?: string;
    backgroundImageMediaId?: string;
    backgroundAttachment?: string;
    backgroundPositionX?: string;
    backgroundPositionY?: string;
    backgroundRepeat?: string;
    backgroundSize?: string;
    backgroundClip?: string;
    backgroundOrigin?: string;
}

export function generateBackgroundColor(color: string) {
    let style = '';
    if (color) {
        style += 'background-color:' + color + ';';
    }
    return style;
}

export function generateBackgroundStyle(
    backgroundStyle: BackgroundStyleDto | null | undefined,
    companyId?: string,
    applicationId?: string
) {
    let style = '';
    if (backgroundStyle) {
        if (backgroundStyle['backgroundColor']) {
            style += 'background-color:' + backgroundStyle['backgroundColor'] + ';';
        }

        if (backgroundStyle['backgroundImageLinkType'] === 'media-library' && backgroundStyle['backgroundImageMediaId']) {
            style +=
                `background-image:url("v2/media/file/company/${companyId}/application/${companyId}/` +
                backgroundStyle['backgroundImageMediaId'] +
                '");';
        }

        if (backgroundStyle['backgroundImageLinkType'] === 'url' && backgroundStyle['backgroundImageUrl']) {
            style += 'background-image:url("' + backgroundStyle['backgroundImageUrl'] + '");';
        }

        if (backgroundStyle['backgroundAttachment']) {
            style += 'background-attachment:' + backgroundStyle['backgroundAttachment'] + ';';
        }
        if (backgroundStyle['backgroundPositionX'] && backgroundStyle['backgroundPositionY']) {
            style += 'background-position:' + backgroundStyle['backgroundPositionX'] + ' ' + backgroundStyle['backgroundPositionY'] + ';';
        }
        if (backgroundStyle['backgroundRepeat']) {
            style += 'background-repeat:' + backgroundStyle['backgroundRepeat'] + ';';
        }
        if (backgroundStyle['backgroundSize']) {
            style += 'background-size:' + backgroundStyle['backgroundSize'] + ';';
        }
        if (backgroundStyle['backgroundOrigin']) {
            style += 'background-origin:' + backgroundStyle['backgroundSize'] + ';';
        }
    }
    return style;
}
