import { GenericStyleDto } from '../../../../../interfaces/style/generic-style.dto';

export interface ImagePartGenericStyleDto extends GenericStyleDto {
    objectFit?: string;
}

export function generateImageStyle(objectFit: string) {
    let style = '';
    if (objectFit) {
        style += 'object-fit:' + objectFit + ';';
    }
    return style;
}
