export interface ShadowStyleDto {
    inset?: boolean;
    offsetX?: string;
    offsetY?: string;
    blurRadius?: string;
    spreadRadius?: string;
    color?: string;
}

export function generateShadowStyle(shadowStyleDto: ShadowStyleDto | null | undefined) {
    let style = '';
    if (shadowStyleDto) {
        style += 'box-shadow:';
        if (shadowStyleDto.offsetX) {
            style += shadowStyleDto.offsetX + ' ';
        } else {
            style += 'none' + ' ';
        }
        if (shadowStyleDto.offsetY) {
            style += shadowStyleDto.offsetY + ' ';
        } else {
            style += 'none' + ' ';
        }

        if (shadowStyleDto.blurRadius) {
            style += shadowStyleDto.blurRadius + ' ';
        } else {
            style += '0';
        }

        if (shadowStyleDto.spreadRadius) {
            style += shadowStyleDto.spreadRadius + ' ';
        } else {
            style += '0';
        }

        if (shadowStyleDto.color) {
            style += shadowStyleDto.color + ' ';
        }

        if (shadowStyleDto.inset) {
            style += 'inset ';
        }

        style += ';';
    }
    return style;
}
