<div
    class="mb-2 relative p-1 flex flex-row items-center border rounded-lg border-light-input-border_color dark:border-dark-input-border_color bg-light-input-background_color dark:bg-dark-input-background_color dark:text-gray-300 cursor-pointer">
    <div
        class="h-6 w-6 relative border-[1px] rounded-md border-black dark:border-white cursor-pointer"
        [showTrigger]="NgxFloatUiTriggers.click"
        [floatUi]="picker"
        [appendTo]="'body'"
        [boundariesElement]="'body'"
        [preventOverflow]="false"
        [positionFixed]="true"
        [placement]="NgxFloatUiPlacements.LEFT"
        (onShown)="onShowColors()"
        [style.background-color]="previewColor"></div>
    <input
        *ngIf="searchTerm"
        type="text"
        class="w-full text-sm pt-0 pb-0 border-0 focus:border-0 focus:ring-0 bg-light-input-background_color dark:bg-dark-input-background_color dark:placeholder-gray-400 dark:text-white cursor-text"
        placeholder="Enter hex value ..."
        hexCharacters
        (focus)="handleInputFocus($event)"
        [formControl]="searchTerm" />
</div>
<float-ui-content #picker>
    <div class="w-72 p-3 bg-white border border-blue-600 rounded-lg dark:border-gray-600 dark:!bg-gray-800">
        <div class="w-full flex items-center justify-between">
            <h3 class="font-semibold text-gray-900 dark:text-white">Choose a Color</h3>
            <div class="button-group" role="group">
                <button
                    *ngIf="designsystem$ | async"
                    type="button"
                    class="button-group-button-left"
                    autofocus
                    [matTooltip]="'Pick a color from your design system'"
                    (click)="handleModeClicked('theme')"
                    [ngClass]="mode === 'theme' ? 'button-group-button-selected' : ''">
                    <svg
                        class="w-5 h-5 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24">
                        <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M12 7h.01m3.486 1.513h.01m-6.978 0h.01M6.99 12H7m9 4h2.706a1.957 1.957 0 0 0 1.883-1.325A9 9 0 1 0 3.043 12.89 9.1 9.1 0 0 0 8.2 20.1a8.62 8.62 0 0 0 3.769.9 2.013 2.013 0 0 0 2.03-2v-.857A2.036 2.036 0 0 1 16 16Z" />
                    </svg>
                </button>
                <button
                    type="button"
                    class="button-group-button-right"
                    [matTooltip]="'Pick a custom color'"
                    (click)="handleModeClicked('custom')"
                    [ngClass]="mode === 'custom' ? 'button-group-button-selected' : ''">
                    <svg
                        class="w-5 h-5 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24">
                        <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M16.872 9.687 20 6.56 17.44 4 4 17.44 6.56 20 16.873 9.687Zm0 0-2.56-2.56M6 7v2m0 0v2m0-2H4m2 0h2m7 7v2m0 0v2m0-2h-2m2 0h2M8 4h.01v.01H8V4Zm2 2h.01v.01H10V6Zm2-2h.01v.01H12V4Zm8 8h.01v.01H20V12Zm-2 2h.01v.01H18V14Zm2 2h.01v.01H20V16Z" />
                    </svg>
                </button>
            </div>
        </div>
        <div *ngIf="mode === 'theme' && designsystem$ | async">
            <h4>Theme</h4>
            <form class="max-w-4xl mx-auto mt-2">
                <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">
                    Search theme colors
                </label>
                <div class="relative">
                    <div class="absolute start-3 inset-y-0 flex items-center pointer-events-none">
                        <svg
                            class="w-4 h-4 text-gray-500 dark:text-gray-400"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 20 20">
                            <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                        </svg>
                    </div>
                    <input
                        type="search"
                        id="default-search"
                        class="block w-full p-2 ps-10 text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white rounded-lg"
                        placeholder="Search design system colors ..."
                        required />
                </div>
            </form>
            <hr class="max-w-4xl h-1 mx-auto !my-2 border-0 rounded border-gray-600 bg-gray-600" />
            <div class="max-w-4xl">
                <ul class="max-w-md space-y-1 text-gray-500 list-inside dark:text-gray-300">
                    <li
                        *ngFor="let preset of presets$ | async | keyvalue: unsorted"
                        class="flex items-center cursor-pointer"
                        (click)="handleThemeColorSelected(preset.value, picker)">
                        <div
                            class="h-4 w-4 border-[1px] rounded border-black dark:border-white"
                            [style.background-color]="preset.value.value"></div>
                        <span class="mx-2 font-bold">{{ preset.value.value }}</span>
                        <span class="block ml-auto">{{ preset.value.name }}</span>
                    </li>
                </ul>
            </div>
        </div>
        <div *ngIf="mode === 'custom' || (designsystem$ | async) === undefined">
            <h4>Custom</h4>
            <chrome-picker
                [control]="chromeControl"
                (colorChange)="handleChromePickerColorChanged($event)"
                class="!w-full !border-0 !shadow-none dark:!bg-gray-800 !mt-2"></chrome-picker>
            <div class="w-full flex justify-end mt-2">
                <button type="button" class="primary-button button-medium" (click)="picker.hide()">Save</button>
            </div>
        </div>
    </div>
</float-ui-content>
