import { ParameterOption } from './parameter-option';
import { Type } from 'class-transformer';
import { Argument } from '../arguments/argument';

export class Parameter {
    id: string;
    sequence: number;
    selectorId: string;
    linkedActionParameterId?: string;
    linkedDataFormatParameterId?: string;
    linkedTemplateParameterId?: string;
    linkedArrayParameterId?: string;
    linkedFilterParams?: string[];

    name: string;
    description: string;
    addComposedButtonText?: string;
    composedTitleSubArgumentId?: string;
    type?: string;
    allowedTypes?: string[];
    subType?: string;
    required?: boolean;
    defaultValue?: Argument;

    hideIfEmptyParameterIds: string[];

    subTypeParameterId?: string;
    subTypePath: string;

    options?: ParameterOption[];

    @Type(() => Parameter)
    parameters?: Parameter[];

    inputType: string | null;

    public isValid() {
        return !!this.name && !!this.type && this.type !== '' && this.isSubParametersValid();
    }

    public isSubParametersValid(): boolean {
        if (!this.parameters || this.parameters.length === 0) {
            return true;
        }
        let valid = true;
        for (const parameter of this.parameters) {
            valid = valid && parameter.isValid();
        }
        return valid;
    }
}
