import { Theme } from './theme.model';

export class DesignSystem {
    id: string;
    applicationId: string;
    companyId: string;

    name: string;
    iconName: string;
    active: boolean;

    standard: Theme;
    dark: Theme;

    deleted: boolean;

    constructor(base?: Partial<DesignSystem>) {
        Object.assign(this, base);
        this.standard = new Theme(base?.standard);
        this.dark = new Theme(base?.dark);
    }

    public static createDeleted(id: string): DesignSystem {
        const dataFormat = new DesignSystem();
        dataFormat.id = id;
        dataFormat.deleted = true;
        return dataFormat;
    }

    public generateVariables(): string {
        let style = 'template-grid {';
        // Color variables
        style += this.dark.primary.variable.css + ':' + this.dark.primary.value + ';';
        style += this.dark.secondary.variable.css + ':' + this.dark.secondary.value + ';';
        style += this.dark.tertiary.variable.css + ':' + this.dark.tertiary.value + ';';
        style += this.dark.alternate.variable.css + ':' + this.dark.alternate.value + ';';
        style += this.dark.primaryBackground.variable.css + ':' + this.dark.primaryBackground.value + ';';
        style += this.dark.secondaryBackground.variable.css + ':' + this.dark.secondaryBackground.value + ';';
        style += this.dark.accentOne.variable.css + ':' + this.dark.accentOne.value + ';';
        style += this.dark.accentTwo.variable.css + ':' + this.dark.accentTwo.value + ';';
        style += this.dark.accentThree.variable.css + ':' + this.dark.accentThree.value + ';';
        style += this.dark.accentFour.variable.css + ':' + this.dark.accentFour.value + ';';
        style += this.dark.primaryText.variable.css + ':' + this.dark.primaryText.value + ';';
        style += this.dark.secondaryText.variable.css + ':' + this.dark.secondaryText.value + ';';
        style += this.dark.success.variable.css + ':' + this.dark.success.value + ';';
        style += this.dark.info.variable.css + ':' + this.dark.info.value + ';';
        style += this.dark.warning.variable.css + ':' + this.dark.warning.value + ';';
        style += this.dark.error.variable.css + ':' + this.dark.error.value + ';';

        // Typography variables
        this.dark.typographies.forEach(t => {
            const vars = t.variableNames;
            if (t.color.reference) {
                const colorDefinition = this.dark.findColorByVariable(t.color.reference.variable);
                style += vars.fontColor.name + ': ' + colorDefinition.variable.css + ';';
            } else if (t.color.hex) {
                const color = t.color.hex.value;
                style += vars.fontColor.name + ': ' + color + ';';
            }
            style += vars.fontFamily.name + ':' + t.fontFamily.fontFamilyNames?.join(',') + ';';
            style += vars.fontSize.name + ':' + t.fontSize.value + t.fontSize.unit + ';';
            style += vars.fontWeight.name + ':' + t.fontWeight.value + ';';
            style += vars.fontStyle.name + ':' + t.fontStyle.keyword.toLocaleLowerCase() + ';';
            style += vars.letterSpacing.name + ':' + t.letterSpacing.value + t.letterSpacing.unit + ';';
        });

        // Globals - Background
        // -> Backgorund color var is appointen in nocode-x-theme.scss
        // style += '.grid {';
        // style += `background-color: var(${this.dark.primaryBackground.cssVariableName});`;
        // style += `}`;

        style += '}';

        return style;
    }
}
