<div class="product" *ngIf="product$ | async as product">
    <div class="product__title">
        <div class="product__image w-[120px] h-[120px] rounded overflow-hidden self-start">
            <img *ngIf="!product.iconUrl" class="object-cover object-center h-full" src="/images/theme/image_placeholder.svg" />
            <img
                *ngIf="!!product.iconUrl"
                class="object-cover object-center h-full"
                [src]="
                    'https://' +
                    environment.backEndHost +
                    '/cdn-cgi/image/width=118,height=118,fit=scale-down,quality=100,format=auto/marketplace/products/' +
                    product.id +
                    '/icon?v=' +
                    product.iconUrl
                " />
        </div>
        <div class="product__header">
            <h1>{{ product.name }}</h1>
            <div class="product__meta-data">
                <div class="product__information text-white">
                    <span>Created by {{ product.createdBy }}</span>
                    <span>|</span>
                    <span>{{ product.createdOn | date: 'medium' }}</span>
                    <span>|</span>
                    <span *ngIf="false">{{ product.stars }} stars</span>
                    <span *ngIf="false">|</span>
                    <span>{{ product.installs }} installs</span>
                    <span>|</span>
                    <span>{{ product.views }} views</span>
                </div>
                <mat-chip-listbox *ngIf="tags$ | async as tags">
                    <mat-chip *ngFor="let tag of tags">{{ tag.tag | titlecase }}</mat-chip>
                </mat-chip-listbox>
            </div>
            <div class="product__description text-white">
                {{ this.descriptionCollapsed ? product.description : (product.description | truncate: 300) }}
                <a
                    *ngIf="product.description.length > 300"
                    class="underline"
                    (click)="this.descriptionCollapsed = !this.descriptionCollapsed"
                    >{{ this.descriptionCollapsed ? ('v2.hub.detail.less' | translate) : ('v2.hub.detail.more' | translate) }}</a
                >
            </div>
        </div>
        <div *ngIf="isAdmin$ | async" class="product__actions">
            <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
            </button>
        </div>
    </div>
    <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="handleEditClick(product)">
            <mat-icon>edit</mat-icon>
            <span>{{ 'v2.hub.detail.menu.edit' | translate }}</span>
        </button>
        <hr class="seperator" />
        <button mat-menu-item (click)="handleAddVersionClicked(product)">
            <mat-icon>add</mat-icon>
            <span>{{ 'v2.hub.detail.menu.versions' | translate }}</span>
        </button>
        <hr class="seperator" />
        <button color="warn" mat-menu-item (click)="handleRemoveClicked(product.id)">
            <mat-icon>remove</mat-icon>
            <span>{{ 'v2.hub.detail.menu.delete' | translate }}</span>
        </button>
    </mat-menu>
    <div class="product__versions">
        <ng-container *ngIf="product.versions.length > 0; else noVersions">
            <mat-card class="version overview-item !h-fit" *ngFor="let version of product.versions">
                <mat-card-header>
                    <div class="mat-card-header-text">
                        <mat-card-title>
                            <div class="version-collapse">
                                <mat-icon *ngIf="!version.collapsed" (click)="version.collapsed = true"> keyboard_arrow_right </mat-icon>
                            </div>
                            <div class="version-collapse">
                                <mat-icon *ngIf="!!version.collapsed" (click)="version.collapsed = false">keyboard_arrow_down </mat-icon>
                            </div>
                            <div class="version-name">{{ version.name }}</div>
                            <div class="version-publish-date">
                                {{ 'v2.hub.detail.tabs.versions.publishDate' | translate }} :
                                {{ version.uploaded | date: 'medium' }}
                            </div>
                            <div class="version-actions items-center" data-name="version-status">
                                <span *ngIf="version.latest" class="latest-chip"
                                    ><mat-icon>new_releases</mat-icon>{{ 'v2.hub.detail.tabs.versions.chips.latest' | translate }}</span
                                >
                                <span
                                    *ngIf="product.installed && version.installed && version.status === 'INSTALLED'"
                                    class="installed-chip"
                                    ><mat-icon>download_done</mat-icon>{{ 'v2.hub.detail.tabs.versions.chips.installed' | translate }}</span
                                >
                                <button
                                    *ngIf="!product.installed"
                                    class="primary-button button-medium"
                                    (click)="handleInstallVersionClicked(product, version)">
                                    <mat-icon>download</mat-icon>
                                    {{ 'v2.hub.detail.tabs.versions.actions.install' | translate }}
                                </button>
                                <button
                                    *ngIf="
                                        product.installed &&
                                        product.installedVersionId !== version.versionId &&
                                        product.installedVersionPublishDate < version.uploaded &&
                                        !(versionChanging$ | async) &&
                                        (currentContext$ | async).selectedApplication.id !== product.applicationId
                                    "
                                    class="primary-button button-medium"
                                    (click)="handleUpgradeClicked(product.id, version.versionId)">
                                    <mat-icon>update</mat-icon>
                                    {{ 'v2.hub.detail.tabs.versions.actions.update' | translate }}
                                </button>
                                <button
                                    *ngIf="product.installed && version.installed && version.status === 'INSTALLED'"
                                    class="destructive-button button-medium"
                                    (click)="handleUninstallClicked(product)">
                                    <mat-icon>delete</mat-icon>
                                    {{ 'v2.hub.detail.menu.uninstall' | translate }}
                                </button>
                            </div>
                        </mat-card-title>
                    </div>
                </mat-card-header>
                <mat-card-content *ngIf="version.collapsed">
                    <p>{{ version.description }}</p>
                </mat-card-content>
                <mat-card-footer *ngIf="versionChanging$ | async as versionId">
                    <mat-progress-bar
                        *ngIf="versionId === version.versionId && version.status !== 'INSTALLED'"
                        mode="indeterminate"></mat-progress-bar>
                </mat-card-footer>
            </mat-card>
        </ng-container>
        <ng-template #noVersions>
            <div class="no-results">
                <img src="/src/images/theme/no_results_found.svg" />
                <h1>{{ 'v2.hub.detail.tabs.versions.errors.no.versions' | translate }}</h1>
            </div>
        </ng-template>
    </div>
    <!--mat-tab-group dynamicHeight>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon>update</mat-icon>
                {{ product.versions.length }} {{ 'v2.hub.detail.tabs.versions.label' | translate }}
            </ng-template>
            <div>

            </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon>comments</mat-icon>
                {{ product.comments.length }} {{ 'v2.hub.detail.tabs.comments.label' | translate }}
            </ng-template>
            <div class="product__comments">
                <div class="buttonContainer">
                    <button mat-raised-button type="button" color="primary" (click)="handleAddCommentClicked()">
                        <mat-icon>add_comment</mat-icon>
                        {{ 'v2.hub.detail.tabs.comments.actions.create' | translate }}
                    </button>
                </div>
                <ng-container *ngIf="product.comments.length > 0; else noComments">
                    <mat-card class="comment" *ngFor="let comment of product.comments">
                        <mat-card-content>
                            <div class="comment-createdby">{{ product.createdBy }}</div>
                            <div class="comment-metadata">{{ comment.createdOn | date: 'medium' }}</div>
                            <p>{{ comment.value }}</p>
                        </mat-card-content>
                    </mat-card>
                </ng-container>
                <ng-template #noComments>
                    <div class="no-results">
                        <img src="/src/images/theme/no_results_found.svg" />
                        <h1>{{ 'v2.hub.detail.tabs.comments.errors.no.comments' | translate }}</h1>
                    </div>
                </ng-template>
            </div>
        </mat-tab>
    </mat-tab-group-->
</div>
