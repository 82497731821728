<div class="title-button-header">
    <h1>{{ title }}</h1>
    <div class="title-button-header-buttons">
        <div id="create-tooltip" role="tooltip" class="primary-button-tooltip">
            {{ createTooltip }}
            <div class="tooltip-arrow" data-popper-arrow></div>
        </div>
        <button
            type="button"
            class="primary-button button-medium"
            data-tooltip-target="create-tooltip"
            data-name="create-new-action"
            (click)="this.create.emit()">
            <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14m-7 7V5" />
            </svg>
            {{ createButtonTitle }}
        </button>
        <ng-container *ngIf="showCopy">
            <div id="copy-tooltip" role="tooltip" class="secondary-button-tooltip">
                {{ copyTooltip }}
                <div class="tooltip-arrow" data-popper-arrow></div>
            </div>
            <button
                type="button"
                class="secondary-button button-medium"
                data-tooltip-target="copy-tooltip"
                data-name="copy-action"
                (click)="this.copy.emit()"
                [disabled]="!enableCopy ? 'disabled' : null">
                <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                    <path
                        fill-rule="evenodd"
                        d="M18 3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1V9a4 4 0 0 0-4-4h-3a1.99 1.99 0 0 0-1 .267V5a2 2 0 0 1 2-2h7Z"
                        clip-rule="evenodd" />
                    <path
                        fill-rule="evenodd"
                        d="M8 7.054V11H4.2a2 2 0 0 1 .281-.432l2.46-2.87A2 2 0 0 1 8 7.054ZM10 7v4a2 2 0 0 1-2 2H4v6a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2h-3Z"
                        clip-rule="evenodd" />
                </svg>
                {{ copyButtonTitle }}
            </button>
        </ng-container>

        <ng-container *ngIf="showDelete">
            <div id="delete-tooltip" role="tooltip" class="destructive-button-tooltip">
                {{ deleteTooltip }}
                <div class="tooltip-arrow" data-popper-arrow></div>
            </div>
            <button
                type="button"
                class="destructive-button button-medium"
                data-tooltip-target="delete-tooltip"
                data-name="delete-action"
                (click)="this.delete.emit()"
                [disabled]="!enableDelete ? 'disabled' : null">
                <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                    <path
                        fill-rule="evenodd"
                        d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z"
                        clip-rule="evenodd" />
                </svg>
                {{ deleteButtonTitle }}
            </button>
        </ng-container>
    </div>
</div>
