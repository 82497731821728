import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CursorStyleDto, ShadowStyleDto } from '@backoffice/data-access/editor';

interface WrapperStyle {
    cursorStyle?: CursorStyleDto | null;
}

@Component({
    selector: 'codex-cursor-picker-wrapper',
    templateUrl: './cursor-picker-wrapper.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CursorPickerWrapperComponent {
    @Input()
    firstPanel: boolean = false;

    @Input()
    lastPanel: boolean = false;

    @Input()
    identifier: string;

    @Output()
    wrapperStyleUpdated: EventEmitter<{
        wrapperStyle: WrapperStyle;
    }> = new EventEmitter<{
        wrapperStyle: WrapperStyle;
    }>();

    @Input()
    wrapperStyle: WrapperStyle;

    @Output()
    hoverWrapperStyleUpdated: EventEmitter<{
        wrapperStyle: WrapperStyle;
    }> = new EventEmitter<{
        wrapperStyle: WrapperStyle;
    }>();

    @Input()
    hoverWrapperStyle: WrapperStyle;

    @Output()
    focusWrapperStyleUpdated: EventEmitter<{
        wrapperStyle: WrapperStyle;
    }> = new EventEmitter<{
        wrapperStyle: WrapperStyle;
    }>();

    @Input()
    focusWrapperStyle: WrapperStyle;

    @Output()
    activeWrapperStyleUpdated: EventEmitter<{
        wrapperStyle: WrapperStyle;
    }> = new EventEmitter<{
        wrapperStyle: WrapperStyle;
    }>();

    @Input()
    activeWrapperStyle: WrapperStyle;

    @Input()
    allowedTypes: string[] = ['normal', 'hover'];

    type: string = 'normal';

    onChangeStyle($event: { cursor: string }) {
        if (!this.wrapperStyle.cursorStyle) {
            this.wrapperStyle.cursorStyle = { type: $event.cursor };
        } else {
            this.wrapperStyle.cursorStyle.type = $event.cursor;
        }
        this.wrapperStyleUpdated.emit({ wrapperStyle: this.wrapperStyle });
    }

    onChangeHoverStyle($event: { cursor: string }) {
        if (!this.hoverWrapperStyle.cursorStyle) {
            this.hoverWrapperStyle.cursorStyle = { type: $event.cursor };
        } else {
            this.hoverWrapperStyle.cursorStyle.type = $event.cursor;
        }
        this.hoverWrapperStyleUpdated.emit({ wrapperStyle: this.hoverWrapperStyle });
    }

    onChangeFocusStyle($event: { cursor: string }) {
        if (!this.focusWrapperStyle.cursorStyle) {
            this.focusWrapperStyle.cursorStyle = { type: $event.cursor };
        } else {
            this.focusWrapperStyle.cursorStyle.type = $event.cursor;
        }
        this.focusWrapperStyleUpdated.emit({ wrapperStyle: this.focusWrapperStyle });
    }

    onChangeActiveStyle($event: { cursor: string }) {
        if (!this.activeWrapperStyle.cursorStyle) {
            this.activeWrapperStyle.cursorStyle = { type: $event.cursor };
        } else {
            this.activeWrapperStyle.cursorStyle.type = $event.cursor;
        }
        this.activeWrapperStyleUpdated.emit({ wrapperStyle: this.activeWrapperStyle });
    }

    resetStyle() {
        if (this.type === 'normal') {
            this.resetWrapperStyle();
        } else if (this.type === 'hover') {
            this.resetHoverWrapperStyle();
        } else if (this.type === 'focus') {
            this.resetFocusWrapperStyle();
        } else if (this.type === 'active') {
            this.resetActiveWrapperStyle();
        }
    }

    resetWrapperStyle() {
        this.wrapperStyle.cursorStyle = null;
        this.wrapperStyleUpdated.emit({ wrapperStyle: this.wrapperStyle });
    }

    resetHoverWrapperStyle() {
        this.hoverWrapperStyle.cursorStyle = null;
        this.hoverWrapperStyleUpdated.emit({ wrapperStyle: this.wrapperStyle });
    }

    resetFocusWrapperStyle() {
        this.focusWrapperStyle.cursorStyle = null;
        this.focusWrapperStyleUpdated.emit({ wrapperStyle: this.wrapperStyle });
    }
    resetActiveWrapperStyle() {
        this.activeWrapperStyle.cursorStyle = null;
        this.activeWrapperStyleUpdated.emit({ wrapperStyle: this.wrapperStyle });
    }
}
