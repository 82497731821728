import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../app/common/statemanagement/state/applicationstate';
import { backofficeEnvironment } from '@shared/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CompanyDto } from '../../../modules/shared/interfaces/company.dto';
import { CompanySecurityDto } from '@shared/interfaces/company-security.dto';

@Injectable()
export class CompanyService {
    constructor(
        private store: Store<ApplicationState>,
        private http: HttpClient
    ) {}

    getCompany(companyId: string): Observable<CompanyDto> {
        return this.http.get<CompanyDto>(`${backofficeEnvironment.rest.company}${companyId}`);
    }

    getCompanySecurity(companyId: string): Observable<CompanySecurityDto> {
        return this.http.get<CompanySecurityDto>(`${backofficeEnvironment.rest.company}${companyId}/security`);
    }
}
