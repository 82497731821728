<ng-container *ngIf="{ partPositioningData: partPositioningData$ | async } as observables">
    <h4 class="!text-sm !font-bold !mb-1 !mt-1">{{ label }}</h4>
    <ng-container *ngIf="showGridInput && observables.partPositioningData?.currentPartPositioning">
        <div class="flex mb-2 gap-2">
            <codex-viewport-selector
                class="w-[calc((100%/2)-5px)] flex gap-1"
                [viewPort]="observables.partPositioningData.currentPartPositioning.sizeX"
                [unit]="observables.partPositioningData.currentPartPositioning.sizeXUnit"
                [allowedUnits]="allowedUnits"
                label="W"
                (viewPortChange)="onChangeSizeX($event)">
            </codex-viewport-selector>
            <codex-viewport-selector
                class="w-[calc((100%/2)-5px)] flex gap-1"
                [viewPort]="observables.partPositioningData.currentPartPositioning.sizeY"
                [unit]="observables.partPositioningData.currentPartPositioning.sizeYUnit"
                [allowedUnits]="allowedUnits"
                label="H"
                (viewPortChange)="onChangeSizeY($event)">
            </codex-viewport-selector>
        </div>
    </ng-container>
    <h4 class="!text-sm !font-bold !mb-1 !mt-1">{{ 'Minimum size' }}</h4>
    <ng-container *ngIf="showGridInput && observables.partPositioningData?.currentPartPositioning">
        <div class="flex mb-2 gap-2">
            <codex-viewport-selector
                class="w-[calc((100%/2)-5px)] flex gap-1"
                [viewPort]="observables.partPositioningData.currentPartPositioning.minSizeX"
                [unit]="observables.partPositioningData.currentPartPositioning.minSizeXUnit"
                [allowedUnits]="allowedUnits"
                label="W"
                (viewPortChange)="onChangeMinSizeX($event)">
            </codex-viewport-selector>
            <codex-viewport-selector
                class="w-[calc((100%/2)-5px)] flex gap-1"
                [viewPort]="observables.partPositioningData.currentPartPositioning.minSizeY"
                [unit]="observables.partPositioningData.currentPartPositioning.minSizeYUnit"
                [allowedUnits]="allowedUnits"
                label="H"
                (viewPortChange)="onChangeMinSizeY($event)">
            </codex-viewport-selector>
        </div>
    </ng-container>
    <h4 class="!text-sm !font-bold !mb-1 !mt-1">{{ 'Maximum size' }}</h4>
    <ng-container *ngIf="showGridInput && observables.partPositioningData?.currentPartPositioning">
        <div class="flex mb-2 gap-2">
            <codex-viewport-selector
                class="w-[calc((100%/2)-5px)] flex gap-1"
                [viewPort]="observables.partPositioningData.currentPartPositioning.maxSizeX"
                [unit]="observables.partPositioningData.currentPartPositioning.maxSizeXUnit"
                [allowedUnits]="allowedUnits"
                label="W"
                (viewPortChange)="onChangeMaxSizeX($event)">
            </codex-viewport-selector>
            <codex-viewport-selector
                class="w-[calc((100%/2)-5px)] flex gap-1"
                [viewPort]="observables.partPositioningData.currentPartPositioning.maxSizeY"
                [unit]="observables.partPositioningData.currentPartPositioning.maxSizeYUnit"
                [allowedUnits]="allowedUnits"
                label="H"
                (viewPortChange)="onChangeMaxSizeY($event)">
            </codex-viewport-selector>
        </div>
    </ng-container>
</ng-container>
