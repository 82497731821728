import { PartDetail } from '../../../part-detail.model';
import { Part } from '../../../part.model';
import { classToPlain, plainToClass, Type } from 'class-transformer';
import { StepperPartStep } from './stepper-part.step';
import { PartSubContainer } from '../../../part-sub-container.model';

export class StepperPartDetail extends PartDetail {
    code: string;

    @Type(() => StepperPartStep)
    steps: StepperPartStep[];

    //transient
    openedStep: number;

    override isValid(): boolean {
        return this.isCodeValid() && this.areStepsValid();
    }

    areStepsValid() {
        if (!!this.steps && this.steps.length > 0) {
            let valid = true;
            for (const step of this.steps) {
                valid = valid && this.isStepValid(step);
            }
            return valid;
        } else {
            return true;
        }
    }

    isStepValid(step: StepperPartStep) {
        return !!step.code && step.code !== '' && !!step.name && step.name !== '';
    }

    isCodeValid(): boolean {
        return !!this.code && this.code !== '';
    }

    override addContainerPart(part: Part) {
        let stepToAddContainerPartTo = this.steps.find(step => step.id === part.secondaryContainerId);
        if (!stepToAddContainerPartTo) {
            stepToAddContainerPartTo = this.steps.find(step => step.code === part.secondaryContainerId);
        }
        if (!!stepToAddContainerPartTo) {
            if (!stepToAddContainerPartTo.parts) {
                stepToAddContainerPartTo.parts = [];
            }
            stepToAddContainerPartTo.parts.push(part);
        }
    }

    override getValidationErrors(): string[] {
        const validationErrors = [];
        if (!this.isCodeValid()) {
            validationErrors.push('v2.part.stepper.error.code');
        }
        if (!this.areStepsValid()) {
            validationErrors.push('v2.part.stepper.error.step');
        }
        return validationErrors;
    }

    override updateContainerPart(partToUpdate: Part): Part {
        const currentPart = this.getChildParts().find(part => part.selectorId === partToUpdate.selectorId);
        if (currentPart) {
            this.removeContainerPart(currentPart.selectorId);
        }
        const plainPartToUpdate = classToPlain(partToUpdate);
        const objectUpdatedPart: Part = plainToClass(Part, plainPartToUpdate);
        let targetStep = this.steps.find(step => step.id === objectUpdatedPart.secondaryContainerId);
        if (!targetStep) {
            targetStep = this.steps.find(step => step.code === objectUpdatedPart.secondaryContainerId);
        }
        if (targetStep) {
            if (!targetStep.parts) {
                targetStep.parts = [];
            }
            targetStep.parts.push(objectUpdatedPart);
        }
        return objectUpdatedPart;
    }

    override removeContainerPart(partSelectorId: string) {
        this.steps.forEach(step => {
            if (step.parts) {
                const foundPartIndex = step.parts.findIndex(part => part.selectorId === partSelectorId);
                if (foundPartIndex > -1) {
                    step.parts.splice(foundPartIndex, 1);
                }
            }
        });
    }

    override getChildParts(): Part[] {
        const childParts: Part[] = [];
        if (this.steps) {
            this.steps.forEach(step => {
                if (step.parts) {
                    step.parts.forEach(part => childParts.push(part));
                }
            });
        }
        return childParts;
    }

    override getSubContainers(): PartSubContainer[] {
        if (this.steps && this.steps.length > 0) {
            return this.steps.map(step => {
                return {
                    id: step.id,
                    code: step.code,
                    name: step.name,
                    icon: step.icon ? step.icon : 'queue_play_next',
                    type: 'step',
                    parts: step.parts,
                };
            });
        } else {
            return [];
        }
    }

    public shouldInitializeChildParts(): boolean {
        return !this.initialized && this.steps?.length > 0;
    }
}
