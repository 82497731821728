import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BackgroundStyleDto, ShadowStyleDto } from '@backoffice/data-access/editor';

interface WrapperStyle {
    shadowStyle?: ShadowStyleDto | null;
}

@Component({
    selector: 'codex-shadow-picker-wrapper',
    templateUrl: './shadow-picker-wrapper.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShadowPickerWrapperComponent {
    @Input()
    firstPanel: boolean = false;

    @Input()
    lastPanel: boolean = false;

    @Input()
    identifier: string;

    @Output()
    wrapperStyleUpdated: EventEmitter<{
        wrapperStyle: WrapperStyle;
    }> = new EventEmitter<{
        wrapperStyle: WrapperStyle;
    }>();

    @Input()
    wrapperStyle: {
        shadowStyle?: ShadowStyleDto | null;
    };

    @Output()
    hoverWrapperStyleUpdated: EventEmitter<{
        wrapperStyle: WrapperStyle;
    }> = new EventEmitter<{
        wrapperStyle: WrapperStyle;
    }>();

    @Input()
    hoverWrapperStyle: WrapperStyle;

    @Output()
    focusWrapperStyleUpdated: EventEmitter<{
        wrapperStyle: WrapperStyle;
    }> = new EventEmitter<{
        wrapperStyle: WrapperStyle;
    }>();

    @Input()
    focusWrapperStyle: WrapperStyle;

    @Output()
    activeWrapperStyleUpdated: EventEmitter<{
        wrapperStyle: WrapperStyle;
    }> = new EventEmitter<{
        wrapperStyle: WrapperStyle;
    }>();

    @Input()
    activeWrapperStyle: WrapperStyle;

    @Input()
    allowedTypes: string[] = ['normal', 'hover'];

    type: string = 'normal';

    onChangeStyle($event: {
        shadow: {
            shadowStyle?: ShadowStyleDto | null;
        };
    }) {
        this.wrapperStyle.shadowStyle = $event.shadow.shadowStyle;
        this.wrapperStyleUpdated.emit({ wrapperStyle: this.wrapperStyle });
    }

    onChangeHoverStyle($event: {
        shadow: {
            shadowStyle?: ShadowStyleDto | null;
        };
    }) {
        this.hoverWrapperStyle.shadowStyle = $event.shadow.shadowStyle;
        this.hoverWrapperStyleUpdated.emit({ wrapperStyle: this.hoverWrapperStyle });
    }
    onChangeFocusStyle($event: {
        shadow: {
            shadowStyle?: ShadowStyleDto | null;
        };
    }) {
        this.focusWrapperStyle.shadowStyle = $event.shadow.shadowStyle;
        this.focusWrapperStyleUpdated.emit({ wrapperStyle: this.focusWrapperStyle });
    }
    onChangeActiveStyle($event: {
        shadow: {
            shadowStyle?: ShadowStyleDto | null;
        };
    }) {
        this.activeWrapperStyle.shadowStyle = $event.shadow.shadowStyle;
        this.activeWrapperStyleUpdated.emit({ wrapperStyle: this.activeWrapperStyle });
    }

    resetStyle() {
        if (this.type === 'normal') {
            this.resetWrapperStyle();
        } else if (this.type === 'hover') {
            this.resetHoverWrapperStyle();
        } else if (this.type === 'focus') {
            this.resetFocusWrapperStyle();
        } else if (this.type === 'active') {
            this.resetActiveWrapperStyle();
        }
    }

    resetWrapperStyle() {
        this.wrapperStyle.shadowStyle = null;
        this.wrapperStyleUpdated.emit({ wrapperStyle: this.wrapperStyle });
    }

    resetHoverWrapperStyle() {
        this.hoverWrapperStyle.shadowStyle = null;
        this.hoverWrapperStyleUpdated.emit({ wrapperStyle: this.hoverWrapperStyle });
    }
    resetFocusWrapperStyle() {
        this.focusWrapperStyle.shadowStyle = null;
        this.focusWrapperStyleUpdated.emit({ wrapperStyle: this.focusWrapperStyle });
    }

    resetActiveWrapperStyle() {
        this.activeWrapperStyle.shadowStyle = null;
        this.activeWrapperStyleUpdated.emit({ wrapperStyle: this.activeWrapperStyle });
    }
}
