export class ChartLegendOptions {
    id?: string;
    show = true;
    zLevel?: number;
    z = 2;
    left?: number | string | 'auto' | 'left' | 'center' | 'right' = 'center';
    top?: number | string | 'auto' | 'top' | 'middle' | 'bottom' = 'bottom';
    right?: number | string = 'auto';
    bottom?: number | string = 'auto';
    width?: number | string = 'auto';
    height?: number | string = 'auto';
    orient?: 'vertical' | 'horizontal' = 'horizontal';
    align?: 'auto' | 'left' | 'right' = 'auto';
    padding?: number | number[] = 5;
    itemGap?: number = 10;
    itemWidth?: number = 25;
    itemHeight?: number = 14;
    data?: ChartLegendDataOptions[];
    type?: 'plain' | 'scroll' = 'plain';

    constructor(base?: Partial<ChartLegendOptions>) {
        if (!!base) {
            this.id = base.id ?? this.id;
            this.show = base.show ?? this.show;
            this.zLevel = base.zLevel ?? this.zLevel;
            this.z = base.z ?? this.z;
            this.left = base.left ?? this.left;
            this.top = base.top ?? this.top;
            this.right = base.right ?? this.right;
            this.bottom = base.bottom ?? this.bottom;
            this.width = base.width ?? this.width;
            this.height = base.height ?? this.height;
            this.orient = base.orient ?? this.orient;
            this.align = base.align ?? this.align;
            this.padding = base.padding ?? this.padding;
            this.itemGap = base.itemGap ?? this.itemGap;
            this.itemWidth = base.itemWidth ?? this.itemWidth;
            this.itemHeight = base.itemHeight ?? this.itemHeight;
            this.data = base.data ?? this.data;
            this.type = base.type ?? this.type;
        }
    }
}

export interface ChartLegendDataOptions {
    name?: string;
    icon?: string;
}
