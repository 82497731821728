<div class="clear-both flex justify-between">
    <label class="small-input-label">{{ 'v2.part.style.background.color' | translate }}</label>
    <nocodex-color-picker-v2
        [hex]="_backgroundStyle.backgroundColor ? _backgroundStyle.backgroundColor : null"
        [colorV2]="!!_backgroundStyle.backgroundColorV2 ? _backgroundStyle.backgroundColorV2 : null"
        (colorChangedV2)="onChangeBackgroundColor($event)"></nocodex-color-picker-v2>
</div>

<div class="button-group" role="group">
    <button
        [matTooltip]="'v2.part.image.from.media.library' | translate"
        type="button"
        class="button-group-button-left"
        autofocus
        (click)="_backgroundStyle.backgroundImageLinkType = 'media-library'"
        [ngClass]="_backgroundStyle.backgroundImageLinkType === 'media-library' ? 'button-group-button-selected' : ''">
        <mat-icon>image</mat-icon>
    </button>

    <button
        [matTooltip]="'v2.part.image.from.url' | translate"
        type="button"
        class="button-group-button-right"
        autofocus
        (click)="_backgroundStyle.backgroundImageLinkType = 'url'"
        [ngClass]="_backgroundStyle.backgroundImageLinkType === 'url' ? 'button-group-button-selected' : ''">
        <mat-icon>http</mat-icon>
    </button>
</div>

<ng-container *ngIf="_backgroundStyle.backgroundImageLinkType === 'url'">
    <label for="background-image-url" class="small-input-label">{{ 'v2.part.style.background.image' | translate }}</label>
    <input
        class="small-input"
        type="text"
        id="background-image-url"
        name="output-name"
        autocomplete="off"
        [(ngModel)]="_backgroundStyle.backgroundImageUrl"
        (change)="onChangeBackground()" />
</ng-container>
<div *ngIf="_backgroundStyle.backgroundImageLinkType === 'media-library'" class="mb-2">
    <codex-media-picker [mediaId]="_backgroundStyle.backgroundImageMediaId" (mediaChanged)="onChangeMediaId($event)" type="image">
    </codex-media-picker>
</div>
<div class="flex flex-row justify-between gap-2 items-center">
    <label for="select-background-attachment" class="small-input-label">{{ 'v2.part.style.background.attachment' | translate }}</label>
    <select
        id="select-background-attachment"
        class="small-input max-w-[170px]"
        data-name="select-background-attachment"
        [(ngModel)]="_backgroundStyle.backgroundAttachment"
        (ngModelChange)="onChangeBackground()">
        <option *ngFor="let backgroundAttachment of backgroundAttachments" [value]="backgroundAttachment">
            {{ backgroundAttachment }}
        </option>
    </select>
</div>

<codex-pixel-selector
    [label]="'v2.part.style.background.position.x' | translate"
    [value]="_backgroundStyle.backgroundPositionX"
    (changePixels)="onChangeBackgroundPositionX($event)"></codex-pixel-selector>

<codex-pixel-selector
    [label]="'v2.part.style.background.position.y' | translate"
    [value]="_backgroundStyle.backgroundPositionY"
    (changePixels)="onChangeBackgroundPositionY($event)"></codex-pixel-selector>

<div class="flex flex-row justify-between gap-2 items-center">
    <label for="select-background-repeat" class="small-input-label">{{ 'v2.part.style.background.repeat' | translate }}</label>
    <select
        id="select-background-repeat"
        class="small-input max-w-[170px]"
        data-name="select-background-attachment"
        [(ngModel)]="_backgroundStyle.backgroundRepeat"
        (ngModelChange)="onChangeBackground()">
        <option *ngFor="let backgroundRepeatItem of backgroundRepeat" [value]="backgroundRepeatItem">{{ backgroundRepeatItem }}</option>
    </select>
</div>

<div class="flex flex-row justify-between gap-2 items-center">
    <label for="select-background-size" class="small-input-label">{{ 'v2.part.style.background.size' | translate }}</label>
    <select
        id="select-background-size"
        class="small-input max-w-[170px]"
        data-name="select-background-size"
        [(ngModel)]="_backgroundStyle.backgroundSize"
        (ngModelChange)="onChangeBackground()">
        <option *ngFor="let backgroundSizeItem of backgroundSize" [value]="backgroundSizeItem">{{ backgroundSizeItem }}</option>
    </select>
</div>

<div class="flex flex-row justify-between gap-2 items-center">
    <label for="select-background-origin" class="small-input-label">{{ 'v2.part.style.background.origin' | translate }}</label>
    <select
        id="select-background-origin"
        class="small-input max-w-[170px]"
        data-name="select-background-origin"
        [(ngModel)]="_backgroundStyle.backgroundOrigin"
        (ngModelChange)="onChangeBackground()">
        <option *ngFor="let backgroundOriginItem of backgroundOrigin" [value]="backgroundOriginItem">{{ backgroundOriginItem }}</option>
    </select>
</div>
