import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GenericStyleComponent } from './generic-style.component';
import { GenericStyleDto, Part } from '@backoffice/data-access/editor';

@Component({
    selector: 'codex-generic-style-component',
    templateUrl: './generic-style-component.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericStyleComponentComponent extends GenericStyleComponent implements OnInit {
    @Input()
    part: Part;

    @Input()
    set genericPartStyle(genericPartStyle: GenericStyleDto) {
        if (genericPartStyle) {
            this._genericPartStyle = genericPartStyle;
        } else {
            this._genericPartStyle = this.defaultStyle() as GenericStyleDto;
        }
    }

    _genericPartStyle: GenericStyleDto;

    @Output()
    genericPartStyleUpdated: EventEmitter<{ genericPartStyle: GenericStyleDto }> = new EventEmitter<{
        genericPartStyle: GenericStyleDto;
    }>();

    constructor(public changeDetectorRef: ChangeDetectorRef) {
        super();
    }

    ngOnInit(): void {}

    getPartStyle(): GenericStyleDto {
        return this._genericPartStyle;
    }

    onStyleUpdated(): void {
        this.genericPartStyleUpdated.emit({ genericPartStyle: this._genericPartStyle });
    }
}
