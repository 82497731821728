import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
    selector: 'codex-select-search',
    templateUrl: './select-search.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectSearchComponent implements OnInit, OnDestroy {
    @Input()
    placeHolder: string;

    @Input()
    items: {
        label: string;
        value: string;
    }[] = [
        {
            label: 'Testje',
            value: 'test',
        },
        {
            label: 'Tristan',
            value: 'test',
        },
    ];

    openedDropdown: boolean = false;

    public filter$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    public filteredItems$: BehaviorSubject<
        {
            label: string;
            value: string;
        }[]
    > = new BehaviorSubject<
        {
            label: string;
            value: string;
        }[]
    >([]);

    public subscriptions: Subscription = new Subscription();

    constructor() {}

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    ngOnInit(): void {
        this.subscriptions.add(this.filter$);
    }

    toggleDropdown() {
        this.openedDropdown = !this.openedDropdown;
    }
}
