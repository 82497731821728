<div class="button-group" role="group">
    <button
        type="button"
        class="button-group-button-middle"
        autofocus
        (click)="_fontStyle.fontAlignment = 'left'; onChangeFontAlignment()"
        [ngClass]="_fontStyle.fontAlignment === 'left' ? 'button-group-button-selected' : ''">
        <svg class="icon" aria-hidden="true" focusable="false">
            <use href="#font_align_left" class="ui-element"></use>
        </svg>
    </button>
    <button
        type="button"
        class="button-group-button-middle"
        autofocus
        (click)="_fontStyle.fontAlignment = 'center'; onChangeFontAlignment()"
        [ngClass]="_fontStyle.fontAlignment === 'center' ? 'button-group-button-selected' : ''">
        <svg class="icon" aria-hidden="true" focusable="false">
            <use href="#font_align_center" class="ui-element"></use>
        </svg>
    </button>
    <button
        type="button"
        class="button-group-button-middle"
        autofocus
        (click)="_fontStyle.fontAlignment = 'right'; onChangeFontAlignment()"
        [ngClass]="_fontStyle.fontAlignment === 'right' ? 'button-group-button-selected' : ''">
        <svg class="icon" aria-hidden="true" focusable="false">
            <use href="#font_align_right" class="ui-element"></use>
        </svg>
    </button>
    <button
        type="button"
        class="button-group-button-middle"
        autofocus
        (click)="_fontStyle.fontAlignment = 'justify'; onChangeFontAlignment()"
        [ngClass]="_fontStyle.fontAlignment === 'justify' ? 'button-group-button-selected' : ''">
        <svg class="icon" aria-hidden="true" focusable="false">
            <use href="#font_align_justify" class="ui-element"></use>
        </svg>
    </button>
</div>

<nocodex-font-family-v2 [value]="_fontStyle.fontFamily" (fontChanged)="onFontFamilyChanged($event)"></nocodex-font-family-v2>
<div class="small-number-wrapper">
    <label class="small-input-label w-1/2">Font-Size</label>
    <codex-viewport-selector
        [viewPort]="_fontStyle.fontSizeValue"
        [unit]="_fontStyle.fontSizeUnit"
        [allowedUnits]="['pixels', 'rem', 'em']"
        (viewPortChange)="onFontSizeChanged($event)"></codex-viewport-selector>
</div>
<div class="small-number-wrapper">
    <label class="small-input-label">Letter spacing</label>
    <codex-viewport-selector
        [viewPort]="_fontStyle.letterSpacingValue"
        [unit]="_fontStyle.letterSpacingUnit"
        [allowedUnits]="['pixels', 'rem', 'em']"
        (viewPortChange)="onLetterSpacingChanged($event)"></codex-viewport-selector>
</div>

<div class="small-number-wrapper">
    <label class="small-input-label">{{ 'v2.part.style.font.lineheight' | translate }}</label>
    <codex-viewport-selector
        [viewPort]="_fontStyle.lineHeightValue"
        [unit]="_fontStyle.lineHeightUnit"
        [allowedUnits]="['pixels', 'rem', 'em']"
        (viewPortChange)="onLineHeightChanged($event)"></codex-viewport-selector>
</div>
<div class="w-full flex flex-row justify-between gap-2 items-center">
    <label class="small-input-label">{{ 'v2.part.style.font.style' | translate }}</label>
    <select class="small-input" [(ngModel)]="_fontStyle.fontStyle" (change)="onChangeFont()">
        <option value="'normal'">Normal</option>
        <option value="'italic'">Italic</option>
        <option value="'oblique'">Oblique</option>
        <option value="'initial'">Initial</option>
        <option value="'inherit'">Inherit</option>
    </select>
</div>
<div class="w-full flex flex-row justify-between gap-2 items-center">
    <label class="small-input-label">{{ 'v2.part.style.font.weight' | translate }}</label>
    <select class="small-input" [(ngModel)]="_fontStyle.fontWeight" (change)="onChangeFont()">
        <option value="100">Thin</option>
        <option value="200">Extra Light</option>
        <option value="300">Light</option>
        <option value="400">Normal</option>
        <option value="500">Medium</option>
        <option value="600">Semi Bold</option>
        <option value="700">Bold</option>
        <option value="800">Extra Bold</option>
        <option value="900">Black</option>
    </select>
</div>

<div class="clear-both flex justify-between">
    <label class="small-input-label">{{ 'v2.part.style.font.color' | translate }}</label>
    <nocodex-color-picker-v2 [value]="_fontStyle.color" (colorChanged)="onChangeColor($event)"></nocodex-color-picker-v2>
</div>

<div class="w-full flex flex-row justify-between gap-2 items-center">
    <label class="small-input-label">{{ 'v2.part.style.font.stretch' | translate }}</label>
    <select class="small-input" [(ngModel)]="_fontStyle.fontStretch" (change)="onChangeFont()">
        <option value="normal">Normal</option>
        <option value="semi-condensed">Semi condensed</option>
        <option value="condensed">Condensed</option>
        <option value="extra-condensed">Extra condensed</option>
        <option value="ultra-condensed">Ultra condensed</option>
        <option value="semi-expanded">Semi expanded</option>
        <option value="expanded">Expanded</option>
        <option value="extra-expanded">Extra expanded</option>
        <option value="ultra-expanded">Ultra expanded</option>
    </select>
</div>

<div class="w-full flex flex-row justify-between gap-2 items-center">
    <label class="small-input-label">{{ 'v2.part.style.font.variant' | translate }}</label>
    <select class="small-input" [(ngModel)]="_fontStyle.fontVariant" (change)="onChangeFont()">
        <option value="normal">Normal</option>
        <option value="small-caps">Small caps</option>
        <option value="initial">Initial</option>
        <option value="inherit">Inherit</option>
    </select>
</div>
<div class="w-full flex flex-row justify-between gap-2 items-center">
    <label class="small-input-label">{{ 'v2.part.style.font.variantcaps' | translate }}</label>
    <select class="small-input" [(ngModel)]="_fontStyle.fontVariantCaps" (change)="onChangeFont()">
        <option value="normal">Normal</option>
        <option value="small-caps">Small caps</option>
        <option value="all-small-caps">All small caps</option>
        <option value="petite-caps">Mini caps</option>
        <option value="all-petite-caps">All mini caps</option>
        <option value="unicase">Unicase</option>
        <option value="titling-caps">Titling caps</option>
        <option value="initial">Initial</option>
        <option value="inherit">Inherit</option>
    </select>
</div>
