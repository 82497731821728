import { PartStyle } from '../../../part-style.model';
import { ContainerStyleDto } from '../../../../../interfaces/style/container.style.dto';
import { GridStyleDto } from '../../../../../interfaces/grid/grid.style.dto';

export class TabsPartStyle extends PartStyle {
    normalStyle: ContainerStyleDto;

    hoverStyle: ContainerStyleDto;

    grid: GridStyleDto;
}
