import { ChangeDetectorRef, Component, EventEmitter, Inject } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA as MAT_DIALOG_DATA, MatDialogRef as MatDialogRef } from '@angular/material/dialog';
import { UploaderOptions, UploadFile, UploadInput, UploadOutput } from 'ngx-uploader';
import { backofficeEnvironment } from '@shared/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ProgressService } from '../../../../../../../../apps/no-code-x-frontoffice/src/app/shared-template/components/progress/progress.service';

@Component({
    selector: 'codex-media-create-form',
    templateUrl: './media-create-form.component.html',
})
export class MediaCreateFormComponent {
    dragOver: boolean;
    files: UploadFile[] = [];
    formGroup = this.fb.nonNullable.group({
        title: ['', Validators.required],
    });
    options: UploaderOptions = {
        concurrency: 1,
        maxUploads: 1,
        maxFileSize: 1024000000,
        allowedContentTypes: [
            'image/gif',
            'image/jpeg',
            'image/png',
            'image/svg',
            'image/svg+xml',
            'video/mp4',
            'audio/mpeg3',
            'audio/mpeg',
            'text/csv',
            'text/xml',
            'application/json',
            'application/gzip',
            'application/msword',
            'application/pdf',
            'application/xml',
            'application/x-zip-compressed',
            'application/zip',
            'application/x-gzip',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        ],
    };
    uploadInput = new EventEmitter<UploadInput>();

    constructor(
        private readonly fb: FormBuilder,
        private readonly snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<MediaCreateFormComponent>,
        private translateService: TranslateService,
        private changeDetectorRef: ChangeDetectorRef,
        public progressService: ProgressService,
        @Inject(MAT_DIALOG_DATA) public data: { token: string; companyId: string; applicationId: string }
    ) {}

    get companyNameControl(): FormControl {
        return this.formGroup.get('title') as FormControl;
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    onCreateMedia(): void {
        const { token, applicationId, companyId } = this.data;
        const event: UploadInput = {
            type: 'uploadAll',
            url: `${backofficeEnvironment.rest.v2.media}company/${companyId}/application/${applicationId}`,
            method: 'POST',
            headers: { Authorization: 'Bearer ' + token },
            data: {
                title: this.formGroup.getRawValue().title,
                fileName: this.files[0].name,
                contentType: this.files[0].type,
                applicationId,
                companyId,
            },
        };
        this.uploadInput.emit(event);
    }

    removeFile(id: string): void {
        this.files = this.files.filter((file: UploadFile) => file.id !== id);
        this.uploadInput.emit({ type: 'remove', id: id });
    }

    onUploadOutput(output: UploadOutput): void {
        console.log(output);
        if (output) {
            switch (output.type) {
                case 'allAddedToQueue':
                    break;
                case 'addedToQueue':
                    if (typeof output.file !== 'undefined') {
                        this.files.push(output.file);
                    }
                    break;
                case 'uploading':
                    if (typeof output.file !== 'undefined') {
                        // update current data in files array for uploading file
                        const index = this.files.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
                        this.files[index] = output.file;
                    }
                    this.progressService.show();
                    this.changeDetectorRef.detectChanges();
                    break;
                case 'removed':
                    // remove file from array when removed
                    this.files = this.files.filter((file: UploadFile) => file !== output.file);
                    break;
                case 'rejected':
                    this.snackBar.open(this.translateService.instant('error.media.upload.rejected'), undefined, {
                        duration: 10000,
                        panelClass: 'error',
                    });
                    this.progressService.hide();
                    break;
                case 'dragOver':
                    this.dragOver = true;
                    break;
                case 'dragOut':
                case 'drop':
                    this.dragOver = false;
                    break;
                case 'done':
                    this.dialogRef.close({
                        id: output.file.response.id,
                        name: this.formGroup.value.title,
                    });
                    this.progressService.hide();
                    break;
            }
            if (output.file) {
                const { responseStatus } = output.file;
                if (responseStatus == 400) {
                    this.snackBar.open(this.translateService.instant('error.media.upload.failed'), undefined, {
                        duration: 10000,
                        panelClass: 'error',
                    });
                    this.progressService.hide();
                    return;
                }
            }
        }
    }
}
