import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { NgxFloatUiContentComponent, NgxFloatUiPlacements, NgxFloatUiTriggers } from 'ngx-float-ui';
import { ColorPickerControl } from '@iplab/ngx-color-picker';
import { BehaviorSubject, distinctUntilChanged, Observable, shareReplay, Subject, Subscription } from 'rxjs';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { debounceTime, filter, map, startWith } from 'rxjs/operators';
import {
    Color,
    ColorDefinition,
    ColorV2,
    DesignSystem,
    DesignSystemEditorFacade,
    Hex,
    Reference,
    Variable,
} from '@backoffice/data-access/editor';

@Component({
    selector: 'nocodex-color-picker-v2',
    templateUrl: './color-picker-v2.component.html',
    styleUrls: ['./color-picker-v2.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ColorPickerV2Component),
            multi: true,
        },
    ],
})
export class ColorPickerV2Component implements OnInit, OnDestroy, ControlValueAccessor {
    private readonly DEFAULT_COLOR = new ColorV2(new Hex('#ffffff', []), null);

    @Input() formControl: FormControl<ColorV2>;
    @Input() designsystemId: string;

    // Used for backwards compatibility
    @Input() value: Color;

    @Output() colorChanged: EventEmitter<{
        value: string;
        reference: string | undefined;
        type: 'RGB' | 'HSV' | 'HSL';
    }> = new EventEmitter();

    @ViewChild('picker') popperContent: NgxFloatUiContentComponent;

    designsystem$: Observable<DesignSystem | undefined>;
    presets$ = new BehaviorSubject<Map<string, ColorDefinition>>(new Map());
    mode: 'theme' | 'custom' = 'theme';
    previewColor = this.DEFAULT_COLOR.hex.value;

    // Controls
    chromeControl: ColorPickerControl;
    searchTerm: FormControl;

    chromePickerChanged$ = new Subject<string>();
    subscription = new Subscription();

    constructor(
        private readonly cdRef: ChangeDetectorRef,
        private readonly designSystemFacade: DesignSystemEditorFacade
    ) {}

    ngOnInit(): void {
        // Als er geen control meegegeven wordt gaan we zelf eentje maken.
        if (!this.formControl) {
            this.formControl = new FormControl(this.DEFAULT_COLOR, { initialValueIsDefault: true });
            this.subscription.add(
                this.formControl.valueChanges.subscribe(color => {
                    this.writeValue(color);
                    this.calculatePreviewColor();
                })
            );

            if (!!this.value) {
                let colorV2;
                if (this.value.reference) {
                    colorV2 = new ColorV2(null, new Reference(new Variable({ name: this.value.reference })));
                } else {
                    colorV2 = new ColorV2(new Hex(this.value.value, []), null);
                }
                this.formControl.setValue(colorV2, { emitEvent: false });
            }
        }

        // Dirty hack but when loading DS we need to link to the current ds. Otherwise, look at the DS that is currently active for the app.
        if (!!this.designsystemId) {
            this.designsystem$ = this.designSystemFacade.findById(this.designsystemId).pipe(startWith(undefined), shareReplay(1));
        } else {
            this.designsystem$ = this.designSystemFacade.activeDesignSystem.pipe(startWith(undefined), shareReplay(1));
        }

        // Slider creates a lot of events. This debounces the events before submitting them to the output
        this.subscription.add(
            this.designsystem$
                .pipe(
                    filter(ds => !!ds),
                    map(ds => (!!ds ? ds.dark.extractColorMap() : new Map()))
                )
                .subscribe(presets => {
                    this.presets$.next(presets);
                    this.calculatePreviewColor();
                    this.cdRef.detectChanges();
                })
        );

        // Controls
        this.searchTerm = new FormControl(this.getDisplayName(this.formControl.value));
        this.chromeControl = new ColorPickerControl().setValueFrom(this.previewColor).hidePresets();

        // Subscriptions
        this.subscription.add(
            this.searchTerm.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe(value => this.handleInputChanged(value))
        );
        this.subscription.add(this.chromePickerChanged$.pipe(debounceTime(300)).subscribe(value => this.handleColorPicker(value)));
    }

    ngOnDestroy() {
        if (this.subscription && !this.subscription.closed) {
            this.subscription.unsubscribe();
        }
    }

    onShowColors() {
        setTimeout(() => {
            if (this.popperContent) {
                this.popperContent.update();
            }
        }, 50);
    }

    handleModeClicked(mode: 'custom' | 'theme') {
        this.mode = mode;
    }

    // Selected color from theme
    handleThemeColorSelected(color: ColorDefinition, picker: any) {
        const value = ColorV2.ofReference(new Reference(new Variable()));
        this.formControl.setValue(value);
        this.calculatePreviewColor();
        picker.hide();
    }

    handleChromePickerColorChanged(color: string): void {
        this.chromePickerChanged$.next(color);
    }

    // Manuel hex value entered
    handleInputChanged(value: string): void {
        const color = ColorV2.ofHex(new Hex(value, []));
        this.formControl.setValue(color);
    }

    handleInputFocus($event: any): void {
        $event.target.select();
    }

    // Chrome picker color selected
    handleColorPicker(value: string): void {
        const color = ColorV2.ofHex(new Hex(value, []));
        this.formControl.setValue(color);
        this.calculatePreviewColor();
    }

    unsorted(): number {
        return 0;
    }

    calculatePreviewColor(): void {
        const value = this.presets$.value;
        const color = this.formControl.value;

        if (!!color.reference) {
            this.previewColor = value.get(color.reference.variable.name).value;
        } else {
            this.previewColor = color.hex.value;
        }
    }

    private getDisplayName(value: ColorV2): string {
        if (!value.reference) {
            return value.hex.value;
        } else {
            const color = this.presets$.value.get(value.reference.variable.name);
            if (!!color) {
                return color.name;
            }
        }

        return '';
    }

    registerOnChange(fn: any): void {
        // not implemented
    }

    registerOnTouched(fn: any): void {
        // not implemented
    }

    setDisabledState(isDisabled: boolean): void {
        // not implemented
    }

    writeValue(color: ColorV2): void {
        // Update controls
        const displayName = this.getDisplayName(color);
        this.searchTerm.setValue(displayName, { emitEvent: false });

        // Send event
        this.colorChanged.emit({
            value: color.hex ? color.hex.value : undefined,
            reference: color.reference ? color.reference.variable.name : undefined,
            type: color.reference ? undefined : 'RGB',
        });

        this.cdRef.detectChanges();
    }

    protected readonly NgxFloatUiTriggers = NgxFloatUiTriggers;
    protected readonly NgxFloatUiPlacements = NgxFloatUiPlacements;

    private rgbaToHex = (colorStr: string) => {
        const forceRemoveAlpha = false;
        // Check if the input string contains '/'
        const hasSlash = colorStr.includes('/');

        if (hasSlash) {
            // Extract the RGBA values from the input string
            const rgbaValues = colorStr.match(/(\d+)\s+(\d+)\s+(\d+)\s+\/\s+([\d.]+)/);

            if (!rgbaValues) {
                return colorStr; // Return the original string if it doesn't match the expected format
            }

            const [red, green, blue, alpha] = rgbaValues.slice(1, 5).map(parseFloat);

            // Convert the RGB values to hexadecimal format
            const redHex = red.toString(16).padStart(2, '0');
            const greenHex = green.toString(16).padStart(2, '0');
            const blueHex = blue.toString(16).padStart(2, '0');

            // Convert alpha to a hexadecimal format (assuming it's already a decimal value in the range [0, 1])
            const alphaHex = forceRemoveAlpha
                ? ''
                : Math.round(alpha * 255)
                      .toString(16)
                      .padStart(2, '0');

            // Combine the hexadecimal values to form the final hex color string
            const hexColor = `#${redHex}${greenHex}${blueHex}${alphaHex}`;

            return hexColor;
        } else {
            // Use the second code block for the case when '/' is not present
            return (
                '#' +
                colorStr
                    .replace(/^rgba?\(|\s+|\)$/g, '') // Get's rgba / rgb string values
                    .split(',') // splits them at ","
                    .filter((string, index) => !forceRemoveAlpha || index !== 3)
                    .map(string => parseFloat(string)) // Converts them to numbers
                    .map((number, index) => (index === 3 ? Math.round(number * 255) : number)) // Converts alpha to 255 number
                    .map(number => number.toString(16)) // Converts numbers to hex
                    .map(string => (string.length === 1 ? '0' + string : string)) // Adds 0 when length of one number is 1
                    .join('')
            );
        }
    };
}
