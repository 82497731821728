<div class="flex gap-1 justify-center items-center" *ngIf="{ basedOnPartPositioning: basedOnPartPositioning$ | async } as observables">
    <ng-container *ngIf="observables.basedOnPartPositioning">
        <mat-icon
            class="!p-0.5 !text-xl flex-none !flex items-center"
            [showTrigger]="NgxFloatUiTriggers.hover"
            [floatUi]="screenTypeInheritPopover"
            appendTo="body"
            [preventOverflow]="false"
            [boundariesElement]="'rootBoundary'"
            [appendTo]="'body'"
            [positionFixed]="true"
            [placement]="NgxFloatUiPlacements.LEFT"
            (onShown)="onShowInformation()"
            >{{ observables.basedOnPartPositioning.icon }}</mat-icon
        >
        <span class="leading-7 grow text-left">{{ 'v2.part.style.size.and.position' | translate }}</span>
        <button
            *ngIf="observables.basedOnPartPositioning.isCurrentScreenType && this.part.positions && this.part.positions.length > 1"
            class="text-button"
            type="button"
            (click)="onResetStyle(); $event.stopPropagation()"
            [matTooltip]="'v2.template.style.reset' | translate">
            Reset
        </button>
    </ng-container>
</div>
<float-ui-content #screenTypeInheritPopover>
    <div class="popover max-w-[250px]">
        {{ 'v2.part.style.size.and.position.screentype.description' | translate }}
    </div>
</float-ui-content>
