import { PartDetail } from '../../../part-detail.model';
import { CheckboxFieldPartChoice } from './checkbox-field-part.choice';

export class CheckboxFieldPartDetail extends PartDetail {
    code: string;
    label: string;

    required: boolean;
    requiredMessage: string | null;

    help: boolean;
    helpInformation: string | null;

    answers: string[];

    choices: CheckboxFieldPartChoice[];

    enabled: boolean;

    addSelectAll: boolean;
    selectAllText: string | null;

    override isValid(): boolean {
        return (
            this.isCodeValid() &&
            this.isLabelValid() &&
            this.areChoicesValid() &&
            this.isRequiredValid() &&
            this.isHelpValid() &&
            this.isAddSelectAllValid()
        );
    }

    override getValidationErrors(): string[] {
        const validationErrors = [];
        if (!this.isCodeValid()) {
            validationErrors.push('v2.part.checkbox-field.error.code');
        }
        if (!this.isLabelValid()) {
            validationErrors.push('v2.part.checkbox-field.error.label');
        }
        if (!this.areChoicesValid()) {
            validationErrors.push('v2.part.checkbox-field.error.choices');
        }
        if (!this.isRequiredValid()) {
            validationErrors.push('v2.part.checkbox-field.error.required');
        }
        if (!this.isHelpValid()) {
            validationErrors.push('v2.part.checkbox-field.error.help');
        }
        if (!this.isAddSelectAllValid()) {
            validationErrors.push('v2.part.checkbox-field.error.add-select-all');
        }
        return validationErrors;
    }

    areChoicesValid() {
        if (!!this.choices && this.choices.length > 0) {
            let valid = true;
            for (const choice of this.choices) {
                valid = valid && this.isChoiceValid(choice);
            }
            return valid;
        } else {
            return true;
        }
    }

    isChoiceValid(choice: CheckboxFieldPartChoice) {
        return !!choice.code && choice.code !== '' && !!choice.label && choice.label !== '' && (!choice.help || !!choice.helpInformation);
    }

    isCodeValid(): boolean {
        return !!this.code && this.code !== '';
    }

    isLabelValid(): boolean {
        return !!this.label && this.label !== '';
    }

    isHelpValid(): boolean {
        return !this.help || (!!this.helpInformation && this.helpInformation !== '');
    }

    isRequiredValid(): boolean {
        return !this.required || (!!this.requiredMessage && this.requiredMessage !== '');
    }

    isAddSelectAllValid(): boolean {
        return !this.addSelectAll || (!!this.selectAllText && this.selectAllText !== '');
    }
}
