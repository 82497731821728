export interface MarginStyleDto {
    margin?: string;
}

export function generateMarginStyle(
    marginStyle: MarginStyleDto,
    marginLeftStyle: MarginStyleDto,
    marginRightStyle: MarginStyleDto,
    marginTopStyle: MarginStyleDto,
    marginBottomStyle: MarginStyleDto
) {
    let style = '';
    if (marginStyle) {
        style += generateTypeMarginStyle('margin', marginStyle);
    }
    if (marginLeftStyle) {
        style += generateTypeMarginStyle('margin-left', marginLeftStyle);
    }
    if (marginTopStyle) {
        style += generateTypeMarginStyle('margin-top', marginTopStyle);
    }
    if (marginRightStyle) {
        style += generateTypeMarginStyle('margin-right', marginRightStyle);
    }
    if (marginBottomStyle) {
        style += generateTypeMarginStyle('margin-bottom', marginBottomStyle);
    }
    return style;
}

export function generateTypeMarginStyle(type: string, marginStyleDto: MarginStyleDto) {
    let style = '';
    if (marginStyleDto.margin) {
        style += type + ':' + marginStyleDto.margin + ';';
    }
    return style;
}
