export interface UpdateTypographyDto {
    applicationId: string;
    companyId: string;
    designSystemId: string;
    color: string;
    colorType: 'RGB' | 'HSV' | 'HSL' | 'REFERENCE';
    fontFamily: string;
    fontSize: number;
    fontWeight: number;
    italic: boolean;
    letterSpacing: number;
    type:
        | 'display_large'
        | 'display_medium'
        | 'display_small'
        | 'headline_large'
        | 'headline_medium'
        | 'headline_small'
        | 'title_large'
        | 'title_medium'
        | 'title_small'
        | 'label_large'
        | 'label_medium'
        | 'label_small'
        | 'body_large'
        | 'body_medium'
        | 'body_small';
    unit: string;
}
