import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-search-header',
    templateUrl: './search-header.component.html',
})
export class SearchHeaderComponent {
    @Input() title: string;

    @Output() create = new EventEmitter();

    @Input() createTooltip: string;

    @Input() createButtonTitle: string;

    @Output() copy = new EventEmitter();

    @Input() copyTooltip: string;

    @Input() copyButtonTitle: string;

    @Input() showCopy = false;

    @Input() enableCopy = false;

    @Output() delete = new EventEmitter();

    @Input() deleteTooltip: string;

    @Input() deleteButtonTitle: string;

    @Input() showDelete = false;

    @Input() enableDelete = false;
}
