import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'codex-pixel-selector',
    templateUrl: './pixel-selector.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PixelSelectorComponent implements OnInit {
    @Input()
    set value(value: string) {
        if (value) {
            this.pixels = parseFloat(value.replace('px', ''));
        } else {
            this.pixels = null;
        }
    }

    @Input()
    minPixels = -5000;

    @Input()
    maxPixels = 5000;

    @Input()
    label: string;

    @Input()
    readonly: boolean;

    pixels = 0;

    @Output()
    changePixels: EventEmitter<{ value: string }> = new EventEmitter();

    constructor() {}

    ngOnInit(): void {}

    onChangePixels($event) {
        if ($event != null) {
            this.changePixels.emit({
                value: $event + 'px',
            });
        }
    }
}
