<mat-dialog-content class="!p-4 scrollbar-primary scrollbar">
    <ng-container *ngIf="error">
        <div class="flex flex-col justify-center items-center">
            <h1 class="!mb-2">Something went wrong while processing the result of your request</h1>
            <span class="!mb-2 text-white text-sm"
                >Our AI is still in Beta and might sometimes produce results that result in errors. Please try again with the same prompt or
                a slightly different one</span
            >
            <hr class="seperator" />
            <span class="!mb-2 text-white p-4 border border-dark-border rounded-lg text-xs bg-dark-input-background_color">{{
                promptMessage
            }}</span>
            <button class="secondary-button button-large" (click)="onRetry()" type="button">
                <mat-icon>retry</mat-icon>
                Retry
            </button>
        </div>
    </ng-container>
    <ng-container *ngIf="!error">
        <ng-container *ngIf="actions">
            <span class="!mb-2 text-white p-4 border border-dark-border rounded-lg text-xs bg-dark-input-background_color">{{
                responseMessage
            }}</span>
            <ng-container *ngFor="let action of actions">
                <h1 class="!mb-2">{{ action.jsonschema.title }} ({{ action.type }})</h1>
                <span class="!mb-2 text-white">{{ action.jsonschema.description }}</span>
                <codex-json-schema-editor
                    class="mb-4"
                    [jsonSchema]="action.jsonschema"
                    [dataFormat]="action.dataFormat"></codex-json-schema-editor>
            </ng-container>
        </ng-container>
        <div class="flex justify-end gap-1">
            <button class="primary-button button-large" (click)="onCreate()" type="button">
                <mat-icon>save</mat-icon>
                {{ 'general.save' | translate }}
            </button>
            <button class="secondary-button button-large" (click)="onCancel()" type="button">
                <mat-icon>cancel</mat-icon>
                {{ 'general.cancel' | translate }}
            </button>
        </div>
    </ng-container>
</mat-dialog-content>
