import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { PartPositioning, selectCurrentPartPositioning, SizeChanged, TemplateEditorFacade } from '@backoffice/data-access/editor';

@Component({
    selector: 'codex-size-picker',
    templateUrl: './size-picker.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SizePickerComponent implements OnDestroy, OnInit {
    @Input()
    label: string;

    @Input()
    showGridInput = true;

    @Input()
    allowedUnits: string[] = ['pixels', 'percentage', 'viewport', 'grid', 'unset', 'fit-content', 'grid-end', 'grid-start'];

    @Output()
    changeSize: EventEmitter<SizeChanged> = new EventEmitter();

    @Input()
    positions: PartPositioning[];

    partPositioningData$: Observable<{
        currentPartPositioning: PartPositioning;
        partPositions: PartPositioning[];
    }> = combineLatest([this.templateEditorFacade.currentScreenType, this.templateEditorFacade.selectedPart]).pipe(
        filter(([currentScreenType, selectedPart]) => (!!currentScreenType || currentScreenType === 0) && !!selectedPart),
        map(([currentScreenType, selectedPart]) => {
            return {
                currentPartPositioning: selectCurrentPartPositioning(
                    currentScreenType,
                    this.positions ? this.positions : selectedPart.positions
                ),
                partPositions: this.positions ? this.positions : selectedPart.positions,
            };
        })
    );

    subscriptions: Subscription = new Subscription();

    constructor(
        public templateEditorFacade: TemplateEditorFacade,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit() {}
    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    onChangeSizeX($event) {
        if ($event.viewPort || $event.unit) {
            this.subscriptions.add(
                this.partPositioningData$.pipe(take(1)).subscribe(partPositioningData => {
                    partPositioningData.currentPartPositioning.sizeX = $event.viewPort;
                    partPositioningData.currentPartPositioning.sizeXUnit = $event.unit;
                    this.onChangeSize(partPositioningData.currentPartPositioning, partPositioningData.partPositions);
                })
            );
        }
    }

    onChangeMinSizeX($event) {
        if ($event.viewPort || $event.unit) {
            this.subscriptions.add(
                this.partPositioningData$.pipe(take(1)).subscribe(partPositioningData => {
                    partPositioningData.currentPartPositioning.minSizeX = $event.viewPort;
                    partPositioningData.currentPartPositioning.minSizeXUnit = $event.unit;
                    this.onChangeSize(partPositioningData.currentPartPositioning, partPositioningData.partPositions);
                })
            );
        }
    }

    onChangeMaxSizeX($event) {
        if ($event.viewPort || $event.unit) {
            this.subscriptions.add(
                this.partPositioningData$.pipe(take(1)).subscribe(partPositioningData => {
                    partPositioningData.currentPartPositioning.maxSizeX = $event.viewPort;
                    partPositioningData.currentPartPositioning.maxSizeXUnit = $event.unit;
                    this.onChangeSize(partPositioningData.currentPartPositioning, partPositioningData.partPositions);
                })
            );
        }
    }

    onChangeSizeY($event) {
        if ($event.viewPort || $event.unit) {
            this.subscriptions.add(
                this.partPositioningData$.pipe(take(1)).subscribe(partPositioningData => {
                    partPositioningData.currentPartPositioning.sizeY = $event.viewPort;
                    partPositioningData.currentPartPositioning.sizeYUnit = $event.unit;
                    this.onChangeSize(partPositioningData.currentPartPositioning, partPositioningData.partPositions);
                })
            );
        }
    }

    onChangeMinSizeY($event) {
        if ($event.viewPort || $event.unit) {
            this.subscriptions.add(
                this.partPositioningData$.pipe(take(1)).subscribe(partPositioningData => {
                    partPositioningData.currentPartPositioning.minSizeY = $event.viewPort;
                    partPositioningData.currentPartPositioning.minSizeYUnit = $event.unit;
                    this.onChangeSize(partPositioningData.currentPartPositioning, partPositioningData.partPositions);
                })
            );
        }
    }

    onChangeMaxSizeY($event) {
        if ($event.viewPort || $event.unit) {
            this.subscriptions.add(
                this.partPositioningData$.pipe(take(1)).subscribe(partPositioningData => {
                    partPositioningData.currentPartPositioning.maxSizeY = $event.viewPort;
                    partPositioningData.currentPartPositioning.maxSizeYUnit = $event.unit;
                    this.onChangeSize(partPositioningData.currentPartPositioning, partPositioningData.partPositions);
                })
            );
        }
    }

    onChangeSize(partPositioning: PartPositioning, existingPartPositionings: PartPositioning[]) {
        const foundPartPositioning = existingPartPositionings.find(
            existingPartPositioning => existingPartPositioning.screenType === partPositioning.screenType
        );

        if (!foundPartPositioning) {
            existingPartPositionings.push(partPositioning);
        } else {
            foundPartPositioning.sizeY = partPositioning.sizeY;
            foundPartPositioning.sizeX = partPositioning.sizeX;
            foundPartPositioning.sizeYUnit = partPositioning.sizeYUnit;
            foundPartPositioning.sizeXUnit = partPositioning.sizeXUnit;

            foundPartPositioning.minSizeY = partPositioning.minSizeY;
            foundPartPositioning.minSizeX = partPositioning.minSizeX;
            foundPartPositioning.minSizeYUnit = partPositioning.minSizeYUnit;
            foundPartPositioning.minSizeXUnit = partPositioning.minSizeXUnit;

            foundPartPositioning.maxSizeY = partPositioning.maxSizeY;
            foundPartPositioning.maxSizeX = partPositioning.maxSizeX;
            foundPartPositioning.maxSizeYUnit = partPositioning.maxSizeYUnit;
            foundPartPositioning.maxSizeXUnit = partPositioning.maxSizeXUnit;

            foundPartPositioning.inheritContentSize = partPositioning.inheritContentSize;
        }
        this.changeSize.emit({
            partPositionings: existingPartPositionings,
        });
    }
}
