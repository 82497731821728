import { combineReducers, createReducer, on } from '@ngrx/store';
import { createEntityAdapter } from '@ngrx/entity';
import { Media } from '../../models/media';
import { MediaEditorState } from '../state/media-editor.state';
import {
    clearMediaDialogData,
    clearMediaDialogFilter,
    deleteMediaSuccess,
    fetchMediaSuccess,
    loadMediaDialogDataSuccess,
    mediaDialogFacetsChanged,
    mediaDialogFilterPluginsChanged,
    mediaDialogPaginationChanged,
    mediaDialogSearchTermChanged,
    updateMediaSuccess,
} from '../actions/media-editor.actions';
import { selectApplicationSuccess } from '../../../../../../../../apps/no-code-x-backoffice/src/app/store/context/context.actions';
import { MediaDialogState } from '../state/media-dialog.state';
import { Page } from '@shared/data-access';
import { SelectedFacets } from '../../dto/overview/facets.dto';

const adapterAction = createEntityAdapter<Media>();
export const mediaInitialState: MediaEditorState = adapterAction.getInitialState();
export const mediaDialogState: MediaDialogState = {
    page: 0,
    maxResults: 10,
    facets: new SelectedFacets(),
    filterPlugins: false,
};

const facetsReducer = (initialData: SelectedFacets) =>
    createReducer(
        initialData,
        on(mediaDialogFacetsChanged, (state, { facets }) => facets),
        on(clearMediaDialogFilter, () => new SelectedFacets())
    );

const filterPluginsReducer = (initialData: boolean | undefined) =>
    createReducer(
        initialData,
        on(mediaDialogFilterPluginsChanged, (state, { filterPlugins }) => filterPlugins),
        on(clearMediaDialogFilter, () => false)
    );

const pageReducer = (initialData: number) =>
    createReducer(
        initialData,
        on(mediaDialogPaginationChanged, (_, { page }) => page),
        on(mediaDialogSearchTermChanged, () => 0),
        on(clearMediaDialogFilter, () => 0)
    );

const maxResultsReducer = (initialData: number) =>
    createReducer(
        initialData,
        on(mediaDialogPaginationChanged, (_, { maxResults }) => maxResults),
        on(clearMediaDialogFilter, () => 10)
    );

const searchTermReducer = (initialData: string | undefined) =>
    createReducer(
        initialData,
        on(mediaDialogSearchTermChanged, (_, { searchTerm }) => searchTerm),
        on(clearMediaDialogFilter, () => undefined)
    );

const resultReducer = (initialData: Page<Media> | undefined) =>
    createReducer(
        initialData,
        on(loadMediaDialogDataSuccess, (_, { data }) => data),
        on(clearMediaDialogData, () => undefined)
    );

export const mediaDialogReducers = combineReducers({
    facets: facetsReducer(mediaDialogState.facets),
    maxResults: maxResultsReducer(mediaDialogState.maxResults),
    page: pageReducer(mediaDialogState.page),
    result: resultReducer(mediaDialogState.result),
    searchTerm: searchTermReducer(mediaDialogState.searchTerm),
    filterPlugins: filterPluginsReducer(mediaDialogState.filterPlugins),
});

export const mediaReducer = (initialData: MediaEditorState) => {
    return createReducer(
        initialData,
        on(fetchMediaSuccess, (state, { media }) => adapterAction.addOne(media, state)),
        on(updateMediaSuccess, (state, { media }) => adapterAction.updateOne({ id: media.id, changes: media }, state)),
        on(deleteMediaSuccess, (state, { id }) => adapterAction.removeOne(id, state)),
        on(selectApplicationSuccess, state => adapterAction.removeAll(state))
    );
};
