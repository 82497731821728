<mat-dialog-content class="!p-4 scrollbar-primary scrollbar">
    <h1 class="!mb-2">Create Data</h1>
    <div class="choice-grid-10">
        <div class="choice-card" [class.choice-card-selected]="selectedType === 'BLANK'" (click)="onSelectType('BLANK')">
            <mat-icon>api</mat-icon>
            <span class="choice-card-title">Blank</span>
        </div>

        <div
            class="choice-card"
            [class.choice-card-selected]="selectedType === 'GENERATE_TESTDATA_FROM_DATAFORMAT'"
            (click)="onSelectType('GENERATE_TESTDATA_FROM_DATAFORMAT')">
            <mat-icon>auto_awesome</mat-icon>
            <span class="choice-card-title">Test data for data-format</span>
        </div>
        <div
            class="choice-card"
            [class.choice-card-selected]="selectedType === 'CREATE_DATA_FROM_CSV'"
            (click)="onSelectType('CREATE_DATA_FROM_CSV')">
            <mat-icon>auto_awesome</mat-icon>
            <span class="choice-card-title">Create data from csv</span>
        </div>
    </div>
    <form *ngIf="formGroup" [formGroup]="formGroup" (ngSubmit)="onCreateData()" novalidate cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
        <ng-container *ngIf="selectedType === 'GENERATE_TESTDATA_FROM_DATAFORMAT'">
            <app-dataformat-picker
                [addQuotesToValue]="false"
                [required]="true"
                [showAddButton]="false"
                [showEditButton]="false"
                (valueUpdated)="onDataFormatPick($event)">
            </app-dataformat-picker>

            <div class="badges mb-2">
                <span
                    id="badge-dismiss-default"
                    *ngFor="let dataFormat of dataFormats"
                    class="inline-flex items-center px-2 py-1 me-2 text-sm font-medium text-blue-800 bg-blue-100 rounded dark:bg-blue-900 dark:text-blue-300">
                    {{ dataFormat.name }}
                    <button
                        type="button"
                        class="inline-flex items-center p-1 ms-2 text-sm text-blue-400 bg-transparent rounded-sm hover:bg-blue-200 hover:text-blue-900 dark:hover:bg-blue-800 dark:hover:text-blue-300"
                        data-dismiss-target="#badge-dismiss-default"
                        aria-label="Remove"
                        (click)="onRemoveDataFormat(dataFormat)">
                        <svg class="w-2 h-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                        </svg>
                        <span class="sr-only">Remove badge</span>
                    </button>
                </span>
            </div>

            <label for="datadescription" class="small-input-label">Describe your test data set</label>
            <textarea
                class="small-input"
                type="text"
                id="dataDescription"
                name="dataDescription"
                formControlName="dataDescription"
                autocomplete="off"></textarea>
        </ng-container>
        <ng-container *ngIf="selectedType === 'CREATE_DATA_FROM_CSV'">
            <label for="datadescription" class="small-input-label">Upload your csv file</label>
            <div
                (uploadOutput)="onUploadOutput($event)"
                [ngClass]="{ 'is-drop-over': dragOver }"
                [options]="options"
                [style.visibility]="this.files && this.files.length >= 1 ? 'hidden' : 'visible'"
                [style.height]="this.files && this.files.length >= 1 ? '0px' : '200px'"
                [uploadInput]="uploadInput"
                class="drop-container mb-2"
                ngFileDrop>
                <h1 class="!mb-2">{{ 'v2.media.edit.dropfile' | translate }}</h1>
                <label for="file-upload" class="upload-button primary-button button-large">
                    <mat-icon>folder</mat-icon>
                    Browse files</label
                >
                <input
                    type="file"
                    id="file-upload"
                    class="hidden"
                    ngFileSelect
                    [options]="options"
                    (uploadOutput)="onUploadOutput($event)"
                    [uploadInput]="uploadInput" />
            </div>
            <ul class="max-w-[600px] w-full mt-4" *ngIf="this.files && this.files.length >= 1">
                <li
                    class="even:bg-gray-50 even:dark:bg-gray-800 odd:bg-white odd:dark:bg-gray-900 rounded-lg mb-1"
                    *ngFor="let file of this.files">
                    <div class="flex items-center space-x-4 rtl:space-x-reverse h-8 p-2">
                        <div class="flex-1 min-w-0">
                            <div class="text-sm font-medium text-gray-900 truncate dark:text-white">
                                {{ file.name }}
                            </div>
                        </div>
                        <div class="inline-flex gap-1 items-center text-base font-semibold text-gray-900 dark:text-white">
                            <button
                                type="button"
                                class="destructive-button button-extra-small"
                                [matTooltip]="'v2.action.picker.remove' | translate"
                                (click)="removeFile(file.id)">
                                <mat-icon>clear</mat-icon>
                            </button>
                        </div>
                    </div>
                </li>
            </ul>
            <label class="toggle-line">
                Create a new Data-format
                <input type="checkbox" class="sr-only peer" formControlName="createDataFormat" />
                <div
                    class="toggle peer-focus:outline-none peer-checked:bg-dark-button-background peer-checked:after:translate-x-full peer rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white"></div>
            </label>
            <app-dataformat-picker
                *ngIf="!this.formGroup.get('createDataFormat').value"
                [addQuotesToValue]="false"
                [required]="true"
                (valueUpdated)="onDataFormatChange($event)">
            </app-dataformat-picker>
        </ng-container>
        <ng-container *ngIf="selectedType === 'BLANK'">
            <h1 class="!mb-2">{{ 'v2.data.create.title' | translate }}</h1>
            <div>
                <label for="dataName" class="small-input-label">{{ 'v2.data.edit.name' | translate }}</label>
                <input
                    class="small-input"
                    type="text"
                    id="dataName"
                    name="dataName"
                    cdkFocusInitial
                    formControlName="dataName"
                    autocomplete="off" />

                <app-dataformat-picker [addQuotesToValue]="false" [required]="true" (valueUpdated)="onDataFormatChange($event)">
                </app-dataformat-picker>

                <ngx-monaco-editor
                    class="!mb-2"
                    *ngIf="!!selectedDataFormatId"
                    [options]="editorOptions"
                    [(ngModel)]="dataBody"
                    (onInit)="this.onInitEditor($event)"
                    [ngModelOptions]="{ standalone: true }"></ngx-monaco-editor>
                <mat-error *ngFor="let error of errors">{{ error }}</mat-error>
            </div>
        </ng-container>
        <ng-container
            *ngIf="
                selectedType === 'GENERATE_TESTDATA_FROM_DATAFORMAT' || selectedType === 'CREATE_DATA_FROM_CSV' || selectedType === 'BLANK'
            ">
            <label for="environment" class="small-input-label">{{ 'v2.usermanagement.users.environment.title' | translate }}</label>
            <select id="environment" class="small-input" formControlName="environment">
                <option [value]="'DEV'">{{ 'v2.usermanagement.users.environment.dev' | translate }}</option>
                <option [value]="'TEST'">{{ 'v2.usermanagement.users.environment.test' | translate }}</option>
                <option [value]="'ACCEPT'">{{ 'v2.usermanagement.users.environment.accept' | translate }}</option>
                <option [value]="'PRODUCTION'">{{ 'v2.usermanagement.users.environment.production' | translate }}</option>
            </select>
        </ng-container>
        <div class="flex gap-1 justify-end">
            <button class="primary-button button-large" type="submit" (click)="onCreateData()" [disabled]="errors.length > 0">
                <mat-icon>save</mat-icon>
                {{ 'general.save' | translate }}
            </button>
            <button class="secondary-button button-large" (click)="onCancel()" type="button">
                <mat-icon>cancel</mat-icon>
                {{ 'general.cancel' | translate }}
            </button>
        </div>
    </form>
</mat-dialog-content>
