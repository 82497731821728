<div class="clear-both flex justify-between">
    <h5>{{ 'v2.part.style.shadow.color' | translate }}</h5>
    <codex-color-picker [color]="_shadowStyle.color" (changeColor)="onChangeColor($event)"></codex-color-picker>
</div>

<codex-pixel-selector
    [label]="'v2.part.style.shadow.offset.x' | translate"
    [value]="_shadowStyle.offsetX"
    (changePixels)="onChangeOffsetX($event)"></codex-pixel-selector>
<codex-pixel-selector
    [label]="'v2.part.style.shadow.offset.y' | translate"
    [value]="_shadowStyle.offsetY"
    (changePixels)="onChangeOffsetY($event)"></codex-pixel-selector>
<codex-pixel-selector
    [label]="'v2.part.style.shadow.blurradius' | translate"
    [value]="_shadowStyle.blurRadius"
    (changePixels)="onChangeBlurRadius($event)"></codex-pixel-selector>
<codex-pixel-selector
    [label]="'v2.part.style.shadow.spreadradius' | translate"
    [value]="_shadowStyle.spreadRadius"
    (changePixels)="onChangeSpreadRadius($event)"></codex-pixel-selector>
