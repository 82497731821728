import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApplicationDto } from '../../../../app/v2-application/dto/application.dto';
import { NgxFloatUiTriggers } from 'ngx-float-ui';

@Component({
    selector: 'codex-application-edit-general',
    templateUrl: './application-edit-general.component.html',
})
export class ApplicationEditGeneralComponent implements OnInit, OnChanges {
    @Input() application: ApplicationDto;

    @Output() updateApplication: EventEmitter<{ application: ApplicationDto }> = new EventEmitter<{ application: ApplicationDto }>();
    @Output() cancelUpdateApplication: EventEmitter<{ application: ApplicationDto }> = new EventEmitter<{ application: ApplicationDto }>();
    @Output() deleteApplication: EventEmitter<{ application: ApplicationDto }> = new EventEmitter<{ application: ApplicationDto }>();
    @Output() validateDomain: EventEmitter<{ application: ApplicationDto }> = new EventEmitter<{ application: ApplicationDto }>();

    formGroup: FormGroup;

    customDomainChecks: { id: string; name: string; ipv4: string; ipv4prd: string; status: string }[] = [
        {
            id: 'DEV_FRONT',
            name: 'dev',
            ipv4: '104.199.38.24',
            ipv4prd: '',
            status: 'pending',
        },
        {
            id: 'TEST_FRONT',
            name: 'test',
            ipv4: '104.199.38.24',
            ipv4prd: '',
            status: 'pending',
        },
        {
            id: 'ACCEPT_FRONT',
            name: 'accept',
            ipv4: '104.199.38.24',
            ipv4prd: '',
            status: 'pending',
        },
        {
            id: 'PRODUCTION_FRONT',
            name: '@',
            ipv4: '104.199.38.24',
            ipv4prd: '',
            status: 'pending',
        },
        {
            id: 'DEV_BACK',
            name: 'dev-back',
            ipv4: '34.79.186.132',
            ipv4prd: '',
            status: 'pending',
        },
        {
            id: 'TEST_BACK',
            name: 'test-back',
            ipv4: '34.79.186.132',
            ipv4prd: '',
            status: 'pending',
        },
        {
            id: 'ACCEPT_BACK',
            name: 'accept-back',
            ipv4: '34.79.186.132',
            ipv4prd: '',
            status: 'pending',
        },
        {
            id: 'PRODUCTION_BACK',
            name: 'back',
            ipv4: '34.79.186.132',
            ipv4prd: '',
            status: 'pending',
        },
    ];

    get nameControl(): FormControl {
        return this.formGroup.get('name') as FormControl;
    }

    get descriptionControl(): FormControl {
        return this.formGroup.get('description') as FormControl;
    }

    get slugControl(): FormControl {
        return this.formGroup.get('slug') as FormControl;
    }

    get customDomainControl(): FormControl {
        return this.formGroup.get('customDomain') as FormControl;
    }

    constructor(
        private readonly fb: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit(): void {
        this.formGroup = this.fb.group({
            name: [this.application.name, Validators.required],
            description: [this.application.description],
            slug: [this.application.applicationSlug, Validators.required],
            customDomain: [
                this.application.customDomain,
                Validators.pattern(/^(?!.* .*)(?:[a-z0-9][a-z0-9-]{0,61}[a-z0-9]\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/),
            ],
        });
        this.updateCustomDomains();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.updateCustomDomains();
    }

    updateCustomDomains() {
        for (const customDomainCheck of this.customDomainChecks) {
            if (customDomainCheck.id === 'DEV_FRONT') {
                this.changeCustomDomainCheckStatus(this.application.customDomainFrontendDevCheck, customDomainCheck);
            } else if (customDomainCheck.id === 'TEST_FRONT') {
                this.changeCustomDomainCheckStatus(this.application.customDomainFrontendTestCheck, customDomainCheck);
            } else if (customDomainCheck.id === 'ACCEPT_FRONT') {
                this.changeCustomDomainCheckStatus(this.application.customDomainFrontendAcceptCheck, customDomainCheck);
            } else if (customDomainCheck.id === 'PRODUCTION_FRONT') {
                this.changeCustomDomainCheckStatus(this.application.customDomainFrontendProductionCheck, customDomainCheck);
            } else if (customDomainCheck.id === 'DEV_BACK') {
                this.changeCustomDomainCheckStatus(this.application.customDomainBackendDevCheck, customDomainCheck);
            } else if (customDomainCheck.id === 'TEST_BACK') {
                this.changeCustomDomainCheckStatus(this.application.customDomainBackendTestCheck, customDomainCheck);
            } else if (customDomainCheck.id === 'ACCEPT_BACK') {
                this.changeCustomDomainCheckStatus(this.application.customDomainBackendAcceptCheck, customDomainCheck);
            } else if (customDomainCheck.id === 'PRODUCTION_BACK') {
                this.changeCustomDomainCheckStatus(this.application.customDomainBackendProductionCheck, customDomainCheck);
            }
        }
    }
    changeCustomDomainCheckStatus(status: string, customDomainCheck: { status: string }) {
        if (status === 'CHECK_SUCCESS') {
            customDomainCheck.status = 'success';
        } else if (status === 'CHECK_FAILED') {
            customDomainCheck.status = 'failed';
        } else if (status === 'CHECK_PENDING') {
            customDomainCheck.status = 'pending';
        }
    }

    onChangeIconName(iconName: string) {
        this.application.iconName = iconName;
    }

    onUpdateApplication() {
        this.application.name = this.nameControl.value;
        this.application.description = this.descriptionControl.value;
        this.application.applicationDomainName = this.application.applicationDomainName.replace(
            this.application.applicationSlug,
            this.slugControl.value
        );
        this.application.applicationSlug = this.slugControl.value;
        this.application.customDomain = this.customDomainControl.value;
        if (this.formGroup.valid) {
            this.updateApplication.emit({ application: this.application });
        }
    }

    onCancel() {
        this.cancelUpdateApplication.emit({ application: this.application });
    }

    onDeleteApplication() {
        this.deleteApplication.emit({ application: this.application });
    }

    onValidateDomains() {
        this.validateDomain.emit({ application: this.application });
    }

    protected readonly NgxFloatUiTriggers = NgxFloatUiTriggers;
}
