import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as WebFont from 'webfontloader';
import { Font, FontInterface } from 'ngx-font-picker';
import { FontStyleDto, generateTypeFontStyle } from '@backoffice/data-access/editor';

declare let $: any;

@Component({
    selector: 'codex-font-picker',
    templateUrl: './font-picker.component.html',
    styleUrls: ['./font-picker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FontPickerComponent implements OnInit {
    fontWeights: string[] = ['normal', 'bold', 'bolder', 'lighter', '100', '200', '300', '400', '500', '600', '700', '800', '900'];

    fontStretchs: string[] = [
        'ultra-condensed',
        'extra-condensed',
        'condensed',
        'semi-condensed',
        'normal',
        'semi-expanded',
        'expanded',
        'extra-expanded',
        'ultra-expanded',
    ];

    fontVariants: string[] = ['normal', 'small-caps', 'initial', 'inherit'];

    fontVariantCaps: string[] = [
        'normal',
        'small-caps',
        'all-small-caps',
        'petite-caps',
        'all-petite-caps',
        'unicase',
        'titling-caps',
        'initial',
        'inherit',
    ];

    @Input()
    set fontStyle(fontStyle: FontStyleDto) {
        if (fontStyle) {
            this._fontStyle = fontStyle;
            this.font = new Font({
                family: this._fontStyle.fontFamily,
                size: this._fontStyle.fontSize,
                style: this._fontStyle.fontStyle,
                styles: [this._fontStyle.fontStyle],
            });
        } else {
            this._fontStyle = this.defaultFontStyle();
        }
    }

    _fontStyle: FontStyleDto;

    public font: FontInterface = new Font({
        family: 'Poppins',
        size: '14px',
        style: 'regular',
        styles: ['regular'],
    });

    @Output()
    changeFont: EventEmitter<{ font: { fontStyle: FontStyleDto } }> = new EventEmitter();

    constructor(private hostElementRef: ElementRef) {}

    ngOnInit(): void {}

    defaultFontStyle() {
        return {
            fontFamily: 'Poppins',
            fontSizeValue: 14,
            fontSizeUnit: 'pixels',
            letterSpacing: null,
            letterSpacingValue: null,
            letterSpacingUnit: 'normal',
            fontUrls: null,
            fontSize: '14px',
            fontWeight: null,
            fontStretch: null,
            fontStyle: 'regular',
            fontVariant: null,
            fontVariantCaps: null,
            fontColor: null,
            color: null,
            fontAlignment: 'justify',
            lineHeight: null,
            lineHeightValue: 14,
            lineHeightUnit: 'pixels',
        };
    }

    // This is a dirty hack to make font-picker correctly close without leaving blank space
    // We have to do this because the lib uses visibility instead of display:none or *ngIf to hide the picker...
    openDialog() {
        // We use the host element because we want multiple font-pickers on one page to mind their own business.
        const target = $(this.hostElementRef.nativeElement).find('font-picker').find('.font-picker')[0];
        const observer = new MutationObserver(mutations => {
            mutations.forEach(mutationRecord => {
                if ($(this.hostElementRef.nativeElement).find('font-picker').find('.font-picker').css('visibility') === 'visible') {
                    $(this.hostElementRef.nativeElement).find('font-picker').attr('class', 'open-picker');
                    $(this.hostElementRef.nativeElement).find('.font-picker').css('top', '0');
                    $(this.hostElementRef.nativeElement).find('.font-picker').css('left', 'unset');
                    $(this.hostElementRef.nativeElement).find('.font-picker').css('right', 'unset');
                } else {
                    $(this.hostElementRef.nativeElement).find('font-picker').attr('class', 'closed-picker');
                }
            });
        });

        observer.observe(target, { attributes: true, attributeFilter: ['style'] });
    }

    onChangeColor($event: { value: string; reference: string | undefined; type: 'RGB' | 'HSV' | 'HSL' }) {
        this._fontStyle.color = $event;
        this.onChangeFont();
    }

    onChangeLineHeight($event: { value: string }) {
        this._fontStyle.lineHeight = $event.value;
        this.onChangeFont();
    }

    onChangeFont() {
        this.changeFont.emit({ font: { fontStyle: this._fontStyle } });
    }

    onChangeFontAlignment() {
        this.changeFont.emit({ font: { fontStyle: this._fontStyle } });
    }

    onFontSizeChanged(fontSizeChanged: { viewPort: number; unit: string }) {
        this._fontStyle.fontSizeValue = fontSizeChanged.viewPort;
        this._fontStyle.fontSizeUnit = fontSizeChanged.unit;
        this.changeFont.emit({ font: { fontStyle: this._fontStyle } });
    }

    onLetterSpacingChanged(fontSizeChanged: { viewPort: number; unit: string }) {
        this._fontStyle.letterSpacingValue = fontSizeChanged.viewPort;
        this._fontStyle.letterSpacingUnit = fontSizeChanged.unit;
        this.changeFont.emit({ font: { fontStyle: this._fontStyle } });
    }

    onLineHeightChanged(fontSizeChanged: { viewPort: number; unit: string }) {
        this._fontStyle.lineHeightValue = fontSizeChanged.viewPort;
        this._fontStyle.lineHeightUnit = fontSizeChanged.unit;
        this.changeFont.emit({ font: { fontStyle: this._fontStyle } });
    }

    onFontFamilyChanged(fontFamilyChanged: { value: string }) {
        this._fontStyle.fontFamily = fontFamilyChanged.value;
        this.changeFont.emit({ font: { fontStyle: this._fontStyle } });
    }
    onFontChange($event) {
        this.font = $event;
        this._fontStyle.fontFamily = $event.family;
        this._fontStyle.fontSize = $event.size;
        this._fontStyle.fontStyle = $event.style;
        if ($event.files) {
            this._fontStyle.fontUrls = {
                _300: $event.files['300'],
                _300italic: $event.files['300italic'],
                _500: $event.files['500'],
                _500italic: $event.files['500italic'],
                _600: $event.files['600'],
                _600italic: $event.files['600italic'],
                _700: $event.files['700'],
                _700italic: $event.files['700italic'],
                _800: $event.files['800'],
                _800italic: $event.files['800italic'],
                _italic: $event.files['italic'],
                _regular: $event.files['regular'],
            };
        }

        try {
            WebFont.load({
                google: {
                    families: [this._fontStyle.fontFamily + ':' + this._fontStyle.fontStyle],
                },
            });
        } catch (e) {
            console.warn('Failed to load the font:', this._fontStyle);
        }

        this.onChangeFont();
    }

    generateStyle() {
        return generateTypeFontStyle(this._fontStyle);
    }
}
