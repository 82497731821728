<mat-list>
    <mat-list-item *ngIf="logs.length == 0">
        <div class="text-xxs dark:text-white mb-1 whitespace-normal">{{ 'v2.logs.none.found' | translate }}</div>
    </mat-list-item>
    <mat-list-item class="!p-0 border-b !h-fit" *ngFor="let logLine of logs">
        <div class="text-xxs flex gap-1 dark:text-white">
            <mat-icon
                class="!h-5 !w-5 !min-w-5 !text-[20px] cursor-pointer"
                *ngIf="expandedLogLines.indexOf(logLine.id) > -1"
                (click)="expandToggle(logLine)">
                expand_more
            </mat-icon>
            <mat-icon
                class="!h-5 !w-5 !min-w-5 !text-[20px] cursor-pointer"
                *ngIf="expandedLogLines.indexOf(logLine.id) === -1"
                (click)="expandToggle(logLine)">
                expand_less
            </mat-icon>
            <mat-icon class="!h-5 !w-5 !min-w-5 !text-[20px] cursor-pointer" [ngClass]="logLine.level" [matTooltip]="logLine.level"
                >{{ getIcon(logLine.level) }}
            </mat-icon>
            <svg
                aria-hidden="true"
                focusable="false"
                *ngIf="logLine?.environment === 'PRODUCTION'"
                [matTooltip]="'v2.logs.production' | translate"
                class="icon released">
                <use href="#version" class="ui-element"></use>
            </svg>
            <svg
                aria-hidden="true"
                focusable="false"
                *ngIf="logLine?.environment === 'ACCEPT'"
                [matTooltip]="'v2.logs.accept' | translate"
                class="icon acceptance">
                <use href="#version" class="ui-element"></use>
            </svg>
            <svg
                aria-hidden="true"
                focusable="false"
                *ngIf="logLine?.environment === 'TEST'"
                [matTooltip]="'v2.logs.test' | translate"
                class="icon test">
                <use href="#version" class="ui-element"></use>
            </svg>
            <svg
                aria-hidden="true"
                focusable="false"
                *ngIf="logLine?.environment === 'DEV'"
                [matTooltip]="'v2.logs.dev' | translate"
                class="icon">
                <use href="#version" class="ui-element"></use>
            </svg>
            <mat-icon
                *ngIf="logLine.actionId"
                class="!h-5 !w-5 !text-[20px] cursor-pointer"
                [ngClass]="logLine.actionId"
                (click)="gotoAction(logLine.actionId, logLine.actionInvocationId)"
                matTooltip="Goto action"
                >bolt
            </mat-icon>
            <mat-icon
                *ngIf="logLine.templateId"
                class="!h-5 !w-5 !min-w-5 !text-[20px] cursor-pointer"
                [ngClass]="logLine.templateId"
                (click)="gotoTemplate(logLine.templateId)"
                matTooltip="Goto template"
                >web
            </mat-icon>
            <div class="align-middle">{{ logLine.date | date: 'yyyy-MM-dd HH:mm:ss' }}</div>
            <div class="align-middle">{{ logLine.message | truncate: 150 }}</div>
        </div>
        <div class="text-xxs mb-1 whitespace-normal pl-6 dark:text-white" *ngIf="expandedLogLines.indexOf(logLine.id) > -1">
            {{ logLine.message }}
        </div>
    </mat-list-item>
</mat-list>
