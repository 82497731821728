import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../app/store/application.state';
import { combineLatest, Observable, of } from 'rxjs';
import { ApplicationDto } from '../../../app/v2-application/dto/application.dto';
import {
    selectCurrentContext,
    selectEditorNavigationRights,
    selectUser,
    selectUserRights,
} from '../../../app/store/data/authenticated.selector';
import { UserContext } from '@shared/interfaces/user-context.interface';
import { CompanyDto } from '@shared/interfaces/company.dto';
import { KeycloakService } from 'keycloak-angular';
import { CompanyService } from '@core/services/company.service';
import { selectApplications, selectSelectedApplication } from '../../../app/v2-application/statemanagement/selectors/application.selector';
import {
    closeEditApplicationDialog,
    closeOnboardingDialog,
    createApplication,
    createApplicationSuccess,
    createCompanyError,
    createCompanySuccess,
    deleteApplication,
    editApplication,
    editCompany,
    openEditApplicationDialog,
    requestUserInformation,
    requestUserRights,
    selectApplication,
    selectCompany,
} from '../../../app/store/context/context.actions';
import { LastUsedApplication } from '../../../app/authenticated/dto/last-used-application.dto';
import { ApplicationService } from '@core/services/application.service';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { CreateCompanyDto } from '@shared/interfaces/create-company.dto';
import { Actions, ofType } from '@ngrx/effects';
import { CalculatedRightsService } from '@core/services/calculated-rights.service';
import { BillingPackage } from '@billing/dto/billingpackage';
import { BillingService } from '@core/services/billing.service';
import { CalculatedRightSet } from '../../../app/authenticated/dto/calculatedrightset';
import { plainToClass } from 'class-transformer';
import { EditorNavigationRights } from '../../../app/authenticated/dto/editornavigationrights';
import {
    selectCompanies,
    selectRecentApplications,
    selectRecentCompanies,
    selectSelectedCompany,
} from '../../../../../../libs/backoffice/feature/company/edit-company/src/lib/statemanagement/selectors/company.selector';
import { setCompanies } from '../../../../../../libs/backoffice/feature/company/edit-company/src/lib/statemanagement/actioncreator/company.actioncreator';

@Injectable()
export class AppFacade {
    currentContext$: Observable<{ userLanguage: string; selectedCompany: CompanyDto; selectedApplication: ApplicationDto }> =
        this.store.select(selectCurrentContext);

    constructor(
        private readonly store: Store<ApplicationState>,
        private readonly keyCloakService: KeycloakService,
        private readonly companyService: CompanyService,
        private readonly appService: ApplicationService,
        private readonly billingService: BillingService,
        private readonly calculatedRightsService: CalculatedRightsService,
        private readonly actions$: Actions
    ) {}

    get companies(): Observable<CompanyDto[]> {
        return this.store.select(selectCompanies);
    }

    get userRights(): Observable<CalculatedRightSet> {
        return this.store.select(selectUserRights);
    }

    get selectEditorNavigationRights(): Observable<EditorNavigationRights> {
        return this.store.select(selectEditorNavigationRights);
    }

    get selectedCompany(): Observable<CompanyDto> {
        return this.store.select(selectSelectedCompany);
    }

    get recentCompanies(): Observable<CompanyDto[]> {
        return this.store.select(selectRecentCompanies);
    }

    get applications(): Observable<ApplicationDto[]> {
        return this.store.select(selectApplications);
    }

    get selectedApplication(): Observable<ApplicationDto> {
        return this.store.select(selectSelectedApplication);
    }

    get recentApplications(): Observable<ApplicationDto[]> {
        return this.store.select(selectRecentApplications);
    }

    get context(): Observable<{ user: any; userLanguage: string; selectedCompany: CompanyDto; selectedApplication: ApplicationDto }> {
        return this.store.select(selectCurrentContext);
    }

    get user(): Observable<UserContext> {
        return this.store.select(selectUser);
    }

    loadRights(): void {
        this.store.dispatch(requestUserRights());
    }

    loadUser(): void {
        this.store.dispatch(requestUserInformation());
    }

    loadCompanies(userId: string): void {
        this.companyService.getUserCompanies(userId).subscribe(companies => this.store.dispatch(setCompanies(companies)));
    }

    selectCompany(companyId: string): void {
        this.store.dispatch(selectCompany({ companyId }));
    }

    selectApplication(applicationId: string): void {
        this.store.dispatch(selectApplication({ applicationId }));
    }

    fetchApplication(applicationId: string): Observable<ApplicationDto> {
        return this.currentContext$.pipe(
            filter(context => !!context && !!context.selectedCompany),
            take(1),
            switchMap(currentContext => this.appService.getApplication(applicationId, currentContext.selectedCompany.id))
        );
    }

    createCompany(dto: CreateCompanyDto): Observable<CompanyDto> {
        return this.companyService.createCompany(dto).pipe(
            map(response => {
                const company = plainToClass(CompanyDto, response);
                this.store.dispatch(
                    createCompanySuccess({
                        company,
                    })
                );
                return response;
            }),
            catchError(() => {
                this.store.dispatch(createCompanyError());
                return of(undefined);
            })
        );
    }

    createApplication(): Observable<string> {
        this.store.dispatch(createApplication());
        return this.actions$.pipe(
            ofType(createApplicationSuccess),
            map(({ application }) => application.id)
        );
    }

    editApplication(application: ApplicationDto): void {
        this.store.dispatch(editApplication({ application }));
    }

    editCompany(company: CompanyDto): void {
        this.store.dispatch(editCompany({ company }));
    }

    deleteApplication(applicationId: string, companyId: string): void {
        this.store.dispatch(deleteApplication({ applicationId, companyId }));
    }

    getCalculatedRights(): Observable<CalculatedRightSet> {
        return combineLatest([
            this.user.pipe(filter(user => !!user && !!user.id)),
            this.currentContext$.pipe(filter(context => !!context && !!context.selectedApplication)),
        ]).pipe(
            switchMap(([user, context]) => {
                return this.calculatedRightsService.getCalculatedRights(
                    context.selectedApplication.id,
                    context.selectedCompany.id,
                    user.id
                );
            })
        );
    }

    getBillingPackage(companyId: string): Observable<BillingPackage> {
        return this.billingService.getBillingPackage(companyId);
    }

    determineRecentApplications(applications: ApplicationDto[], recentCookie: LastUsedApplication[]): ApplicationDto[] {
        const recentApplications = recentCookie
            .map(r => {
                const index = applications.findIndex(a => a.id === r.id);
                return applications[index];
            })
            .filter(a => !!a);
        if (recentApplications.length === 0) {
            return applications.slice(0, 5);
        } else {
            if (recentApplications.length >= 5) {
                return recentApplications.slice(0, 5);
            } else {
                const notInRecent = applications.filter(company => recentApplications.indexOf(company) === -1);
                return [...recentApplications, ...notInRecent].slice(0, 5);
            }
        }
    }

    determineRecentCompanies(companies: CompanyDto[], recentCookie: string[]): CompanyDto[] {
        if (recentCookie.length === 0) {
            return companies.slice(0, 5);
        } else {
            const relevantCookies: string[] = companies.map(c => c.id).filter(id => recentCookie.includes(id));
            const recentCompanies = relevantCookies.map(id => {
                const index = companies.findIndex(c => c.id === id);
                return companies[index];
            });
            if (recentCompanies.length >= 5) {
                return recentCompanies.slice(0, 5);
            } else {
                const leftToSelect = 5 - recentCompanies.length;
                const notInRecent = companies.filter(company => recentCompanies.indexOf(company) === -1);
                return [...recentCompanies, ...notInRecent.slice(0, leftToSelect - 1)];
            }
        }
    }

    closeOnboardingDialog(): void {
        this.store.dispatch(closeOnboardingDialog());
    }

    openEditApplicationDialog(applicationId: string): void {
        this.fetchApplication(applicationId)
            .pipe(
                take(1),
                filter(application => !!application),
                tap(application => this.store.dispatch(openEditApplicationDialog({ application })))
            )
            .subscribe();
    }

    closeEditApplicationDialog(action: string, application: ApplicationDto): void {
        this.store.dispatch(closeEditApplicationDialog({ application, action }));
    }

    validateDomain(application: ApplicationDto): Observable<void> {
        return this.appService.validateDomain(application.companyId, application.id);
    }
}
