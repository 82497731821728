import { combineReducers, createReducer, on } from '@ngrx/store';
import { createEntityAdapter } from '@ngrx/entity';
import { Data } from '../../../../../../../../apps/no-code-x-backoffice/src/app/v2-data/model/data';
import { DataEditorState } from '../state/data-editor.state';
import {
    clearDataDialogData,
    clearDataDialogFilter,
    dataDialogFacetsChanged,
    dataDialogFilterPluginsChanged,
    dataDialogPaginationChanged,
    dataDialogSearchTermChanged,
    deleteDataFromStore,
    deleteDataSuccess,
    fetchDataSuccess,
    loadDataDialogSuccess,
    updateDataSuccess,
} from '../actions/data-editor.actions';
import { selectApplicationSuccess } from '../../../../../../../../apps/no-code-x-backoffice/src/app/store/context/context.actions';
import { SelectedFacets } from '../../dto/overview/facets.dto';
import { Page } from '@shared/data-access';
import { DataDialogState } from '../state/data-dialog.state';
import { OverviewDataDto } from '../../dto/overview/overview-data.dto';

const adapterAction = createEntityAdapter<Data>();
export const dataInitialState: DataEditorState = adapterAction.getInitialState();
export const dataDialogState: DataDialogState = {
    page: 0,
    maxResults: 10,
    facets: new SelectedFacets(),
    filterPlugins: false,
};

export const dataReducer = (initialData: DataEditorState) => {
    return createReducer(
        initialData,
        on(fetchDataSuccess, (state, { data }) => adapterAction.addOne(data, state)),
        on(updateDataSuccess, (state, { data }) => adapterAction.updateOne({ id: data.id, changes: data }, state)),
        on(deleteDataSuccess, deleteDataFromStore, (state, { id }) => adapterAction.removeOne(id, state)),
        on(selectApplicationSuccess, state => adapterAction.removeAll(state))
    );
};

const facetsReducer = (initialData: SelectedFacets | undefined) =>
    createReducer(
        initialData,
        on(dataDialogFacetsChanged, (state, { facets }) => facets),
        on(clearDataDialogFilter, () => new SelectedFacets())
    );

const filterPluginsReducer = (initialData: boolean | undefined) =>
    createReducer(
        initialData,
        on(dataDialogFilterPluginsChanged, (state, { filterPlugins }) => filterPlugins),
        on(clearDataDialogFilter, () => false)
    );

const pageReducer = (initialData: number) =>
    createReducer(
        initialData,
        on(dataDialogPaginationChanged, (_, { page }) => page),
        on(dataDialogSearchTermChanged, () => 0),
        on(clearDataDialogFilter, () => 0)
    );

const maxResultsReducer = (initialData: number) =>
    createReducer(
        initialData,
        on(dataDialogPaginationChanged, (_, { maxResults }) => maxResults),
        on(clearDataDialogFilter, () => 10)
    );

const searchTermReducer = (initialData: string | undefined) =>
    createReducer(
        initialData,
        on(dataDialogSearchTermChanged, (_, { searchTerm }) => searchTerm),
        on(clearDataDialogFilter, () => undefined)
    );

const resultReducer = (initialData: Page<OverviewDataDto> | undefined) =>
    createReducer(
        initialData,
        on(loadDataDialogSuccess, (_, { data }) => data),
        on(clearDataDialogData, () => undefined)
    );

export const dataDialogReducers = combineReducers({
    page: pageReducer(dataDialogState.page),
    maxResults: maxResultsReducer(dataDialogState.maxResults),
    searchTerm: searchTermReducer(dataDialogState.searchTerm),
    result: resultReducer(dataDialogState.result),
    facets: facetsReducer(dataDialogState.facets),
    filterPlugins: filterPluginsReducer(dataDialogState.filterPlugins),
});
