<ng-container *ngIf="{ partPositioningData: partPositioningData$ | async } as observables">
    <div class="flex flex-col" *ngIf="observables.partPositioningData?.currentPartPositioning">
        <h4 class="!text-sm !font-bold !mb-1 !mt-1">{{ 'v2.style.position' | translate }}</h4>
        <div class="flex flex-row mb-2 justify-between gap-2">
            <div class="button-group" role="group">
                <button
                    type="button"
                    class="button-group-button-left"
                    autofocus
                    [matTooltip]="'v2.style.position.grid-horizontal-alignment.left' | translate"
                    (click)="onChangeHorizontalAlignment('left')"
                    [ngClass]="
                        observables.partPositioningData.currentPartPositioning.horizontalAlignment === 'left'
                            ? 'button-group-button-selected'
                            : ''
                    ">
                    <mat-icon>align_horizontal_left</mat-icon>
                </button>
                <button
                    type="button"
                    class="button-group-button-right"
                    autofocus
                    [matTooltip]="'v2.style.position.grid-horizontal-alignment.right' | translate"
                    (click)="onChangeHorizontalAlignment('right')"
                    [ngClass]="
                        observables.partPositioningData.currentPartPositioning.horizontalAlignment === 'right'
                            ? 'button-group-button-selected'
                            : ''
                    ">
                    <mat-icon>align_horizontal_right</mat-icon>
                </button>
            </div>
            <div class="button-group" role="group">
                <button
                    type="button"
                    class="button-group-button-left"
                    autofocus
                    [matTooltip]="'v2.style.position.grid-vertical-alignment.top' | translate"
                    (click)="onChangeVerticalAlignment('top')"
                    [ngClass]="
                        observables.partPositioningData.currentPartPositioning.verticalAlignment === 'top'
                            ? 'button-group-button-selected'
                            : ''
                    ">
                    <mat-icon>align_vertical_top</mat-icon>
                </button>
                <button
                    type="button"
                    class="button-group-button-right"
                    autofocus
                    [matTooltip]="'v2.style.position.grid-vertical-alignment.bottom' | translate"
                    (click)="onChangeVerticalAlignment('bottom')"
                    [ngClass]="
                        observables.partPositioningData.currentPartPositioning.verticalAlignment === 'bottom'
                            ? 'button-group-button-selected'
                            : ''
                    ">
                    <mat-icon>align_vertical_bottom</mat-icon>
                </button>
            </div>
        </div>
        <div class="flex mb-2 gap-2">
            <codex-viewport-selector
                class="w-[calc((100%/2)-5px)] flex gap-1"
                [viewPort]="observables.partPositioningData.currentPartPositioning.x"
                [unit]="observables.partPositioningData.currentPartPositioning.positionXUnit"
                (viewPortChange)="onChangeX($event)"
                [allowedUnits]="['pixels', 'percentage', 'viewport', 'grid']"
                label="X">
            </codex-viewport-selector>
            <codex-viewport-selector
                class="w-[calc((100%/2)-5px)] flex gap-1"
                [indicateResponsiveSettings]="responseSettingsForY(observables.partPositioningData)"
                [viewPort]="observables.partPositioningData.currentPartPositioning.y"
                [unit]="observables.partPositioningData.currentPartPositioning.positionYUnit"
                (viewPortChange)="onChangeY($event)"
                [allowedUnits]="['pixels', 'percentage', 'viewport', 'grid']"
                label="Y">
            </codex-viewport-selector>
        </div>

        <div class="small-number-wrapper">
            <label [attr.for]="'z-index-'" class="small-input-label">
                {{ 'v2.part.style.position.zindex' | translate }}
            </label>
            <input
                [attr.id]="'z-index-'"
                class="small-input small-number-input"
                required
                type="number"
                [(ngModel)]="observables.partPositioningData.currentPartPositioning.zIndex"
                (ngModelChange)="onChangeZIndex($event)" />
        </div>

        <label class="toggle-line">
            {{ 'v2.style.position.fixed' | translate }}
            <input
                type="checkbox"
                value=""
                class="sr-only peer"
                [(ngModel)]="observables.partPositioningData.currentPartPositioning.fixedPosition"
                [ngModelOptions]="{ standalone: true }"
                (ngModelChange)="onChangeFixedPosition()" />
            <div
                class="toggle peer-focus:outline-none peer-checked:bg-dark-button-background peer-checked:after:translate-x-full peer rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white"></div>
        </label>

        <label class="toggle-line">
            {{ 'v2.style.position.hidden' | translate }}
            <input
                type="checkbox"
                value=""
                class="sr-only peer"
                [(ngModel)]="observables.partPositioningData.currentPartPositioning.hidden"
                [ngModelOptions]="{ standalone: true }"
                (ngModelChange)="onChangeHidden()" />
            <div
                class="toggle peer-focus:outline-none peer-checked:bg-dark-button-background peer-checked:after:translate-x-full peer rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white"></div>
        </label>
    </div>
</ng-container>
