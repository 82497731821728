import { combineReducers, createReducer, on } from '@ngrx/store';
import {
    actionDialogFacetsChanged,
    actionDialogFilterPluginsChanged,
    actionDialogPaginationChanged,
    actionDialogSearchTermChanged,
    clearActionDialogData,
    clearActionDialogFilter,
    deleteActionSuccess,
    fetchActionError,
    fetchActionSuccess,
    loadActionDialogSuccess,
    updateAction,
} from '../actions/action-editor.actions';
import { createEntityAdapter } from '@ngrx/entity';
import { ActionEditorState } from '../state/action-editor.state';
import { Action } from '../../action/model/action';
import { selectApplicationSuccess } from '../../../../../../../../apps/no-code-x-backoffice/src/app/store/context/context.actions';
import { Page } from '@shared/data-access';
import { ActionDialogState } from '../state/action-dialog.state';
import { OverviewActionDto } from '../../dto/overview/overview-action.dto';
import { SelectedFacets } from '../../dto/overview/facets.dto';

const adapterAction = createEntityAdapter<Action>();
export const actionInitialState: ActionEditorState = adapterAction.getInitialState();
export const actionDialogState: ActionDialogState = {
    page: 0,
    maxResults: 10,
    facets: new SelectedFacets(),
    filterPlugins: false,
};

export const actionsReducer = (initialData: ActionEditorState) =>
    createReducer(
        initialData,
        on(fetchActionSuccess, fetchActionError, (state, { action }) => adapterAction.addOne(action, state)),
        on(updateAction, (state, { action }) => adapterAction.updateOne({ id: action.id, changes: action }, state)),
        on(deleteActionSuccess, (state, { id }) => adapterAction.removeOne(id, state)),
        on(selectApplicationSuccess, state => adapterAction.removeAll(state))
    );

const facetsReducer = (initialData: SelectedFacets | undefined) =>
    createReducer(
        initialData,
        on(actionDialogFacetsChanged, (state, { facets }) => facets),
        on(clearActionDialogFilter, () => new SelectedFacets())
    );

const filterPluginsReducer = (initialData: boolean | undefined) =>
    createReducer(
        initialData,
        on(actionDialogFilterPluginsChanged, (state, { filterPlugins }) => filterPlugins),
        on(clearActionDialogFilter, () => false)
    );

const pageReducer = (initialData: number) =>
    createReducer(
        initialData,
        on(actionDialogPaginationChanged, (_, { page }) => page),
        on(actionDialogSearchTermChanged, () => 0),
        on(actionDialogFacetsChanged, () => 0),
        on(clearActionDialogFilter, () => 0)
    );

const maxResultsReducer = (initialData: number) =>
    createReducer(
        initialData,
        on(actionDialogPaginationChanged, (_, { maxResults }) => maxResults),
        on(clearActionDialogFilter, () => 10)
    );

const searchTermReducer = (initialData: string | undefined) =>
    createReducer(
        initialData,
        on(actionDialogSearchTermChanged, (_, { searchTerm }) => searchTerm),
        on(clearActionDialogFilter, () => undefined)
    );

const resultReducer = (initialData: Page<OverviewActionDto> | undefined) =>
    createReducer(
        initialData,
        on(loadActionDialogSuccess, (_, { data }) => data),
        on(clearActionDialogData, () => undefined)
    );

export const actionDialogReducers = combineReducers({
    page: pageReducer(actionDialogState.page),
    maxResults: maxResultsReducer(actionDialogState.maxResults),
    searchTerm: searchTermReducer(actionDialogState.searchTerm),
    result: resultReducer(actionDialogState.result),
    facets: facetsReducer(actionDialogState.facets),
    filterPlugins: filterPluginsReducer(actionDialogState.filterPlugins),
});
